import { Icon } from '@damen/ui'
import React, { useMemo } from 'react'
import { LogoProps } from './types'
import { getNextPublicSite } from '@/utils/env'
import { Sites } from '@/queries/schema.generated'

export const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({ fill, height, width }) => {
  // If we need more site dependant data, we would like to manage that in one place
  const site = getNextPublicSite()

  return useMemo(() => {
    switch (site) {
      case Sites.Albwardydamen:
        return <Icon.LogoAlbwardy fill={fill} height={height} width={width} />
      case Sites.Careerdamen:
        return <Icon.LogoDamen fill={fill} height={height} width={width} />
      case Sites.Nironstaal:
        return <Icon.LogoDamen fill={fill} height={height} width={width} />
      case Sites.Nlnavydamen:
        return <Icon.LogoDamen fill={fill} height={height} width={width} />
      case Sites.Denavydamen:
        return <Icon.LogoDamen fill={fill} height={height} width={width} />
      case Sites.Shipleasefinance:
        return <Icon.LogoDamen fill={fill} height={height} width={width} />

      case Sites.Damen:
      default:
        return <Icon.LogoDamen fill={fill} height={height} width={width} />
    }
  }, [site, fill, height, width])
}
