import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Icon, MediaGallery, Modal, NextImage, Slider, theme, Video, Media, utils } from '@damen/ui'
import type { Asset } from '@damen/ui/lib/esm/types/assets'
import type {
  ImageProps,
  MediaFilters,
  TabItemProps,
  VideoProps,
  CustomAssetProps as CustomGalleryAssetProps,
} from '@damen/ui/lib/esm/components/MediaGallery/types'
import { CustomAssetProps } from '@/patterns/StandardProductDetails'

const { spacing, colors, mediaQueries } = theme

const { getNextImageProps } = utils

type PreviewLayoutType = 'default' | 'wide'
type HeadingSize = 'H2' | 'H4'

export interface Props {
  title?: string
  items?: CustomAssetProps[]
  virtualTourButton?: React.ComponentType
  galleryItems: {
    tabs: TabItemProps[]
    assets: (ImageProps | VideoProps | CustomGalleryAssetProps)[]
    backToOverviewTitle: string
  }
  previewLayout?: PreviewLayoutType
  headingSize?: HeadingSize
  galleryFullscreenButtonText?: string
}

const DESKTOP_SIZE_WIDTH = 587
const DESKTOP_SIZE_HEIGHT = 587
const TABLET_SIZE_WIDTH = 688
const TABLET_SIZE_HEIGHT = 365
const MOBILE_SIZE_WIDTH = 343
const MOBILE_SIZE_HEIGHT = 265

const Gallery = styled.div`
  position: relative;
`

const StyledMainImage = styled.div`
  height: 100%;
  width: 100%;
`

const StyledSmallImage = styled(NextImage)`
  width: 100%;
  height: auto;
  transition: all 0.2s ease-in-out;
  aspect-ratio: 1 / 1;
`

const AssetsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: ${spacing.x1}px;

  @media ${mediaQueries.mediaQueryTablet} {
    margin-top: 0;
  }
`

const MainAssetWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  @media ${mediaQueries.mediaQueryTablet} {
    aspect-ratio: ${TABLET_SIZE_WIDTH} / ${TABLET_SIZE_HEIGHT};
  }

  @media ${mediaQueries.mediaQueryDesktop} {
    aspect-ratio: 1 / 1;
  }
`

const AssetWrapper = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  background: none;
  border: 0;
  padding: 0;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`

const Assets = styled.div`
  margin-top: ${spacing.x1}px;

  // Margin left and right is to compensate for the padding of the slider
  // Margin bottom is to compenstae the height which would be 4px to high
  .slick-list {
    margin: 0 -${spacing.x05}px -${spacing.x05}px -${spacing.x05}px;
  }

  .slick-slide {
    padding: 0 ${spacing.x05}px;
  }
`

const MainImage = styled.div`
  width: 100%;
`

const StyledPlayButton = styled.div`
  svg {
    width: 20px !important;
    height: 20px !important;
  }
`

const ThumbnailIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const EnlargeButton = styled.button`
  position: absolute;
  display: flex;
  z-index: 1;
  align-items: center;
  gap: ${spacing.x1}px;
  top: ${spacing.x1}px;
  right: ${spacing.x1}px;
  padding: ${spacing.x1}px;
  color: ${colors.blue};
  background: ${colors.white};
  border: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    svg {
      transform: rotate(45deg);
    }
  }

  @media ${mediaQueries.mediaQueryDesktop} {
    top: ${spacing.x2}px;
    right: ${spacing.x2}px;
  }
`

const ButtonDescription = styled.span.withConfig({ shouldForwardProp: (prop) => prop !== 'show' })<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
`

const GalleryPreview: React.FC<Props> = ({ items, galleryItems, galleryFullscreenButtonText }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeGalleryTab, setActiveGalleryTab] = useState<MediaFilters>('all')
  const [activeGalleryItem, setActiveGalleryItem] = useState(0)
  const [shouldModalContentScroll, setShouldModalContentScroll] = useState(false)
  const [activeAsset, setActiveAsset] = useState<CustomAssetProps>(items[0])

  const sliderRef = useRef<any>(null)

  const sliderSettings = {
    slidesToShow: 5,
    dynamicHeight: false,
    infinite: items.length > 5,
    arrows: items.length > 5,
    draggable: items.length > 5,
    hidePaging: true,
    centerMode: false,
  }

  // Items with a link are not supported in the MediaGallery, so we don't want them to show up in the Preview.
  const filteredItems = useMemo(() => items?.filter((item) => item.linkComponent === undefined), items ?? [])

  const openAssetInModal = useCallback((asset: CustomAssetProps) => {
    if (galleryItems) {
      if (galleryItems.tabs.length > 1) {
        setActiveGalleryItem(
          galleryItems.assets
            .filter((item: any) => item.category === asset.type)
            .findIndex((item: any) => item.id === asset.id),
        )
      } else {
        setActiveGalleryItem(galleryItems.assets.findIndex((item: any) => item.id === asset.id))
      }
    }
    setActiveGalleryTab(asset.type as MediaFilters)
    setIsModalOpen(true)
  }, [])

  const { srcSet: desktopSize } = getNextImageProps({
    image: {
      src: activeAsset.overviewImage.src,
      alt: activeAsset.overviewImage.alt,
    },
    aspectRatio: '1:1',
    imageProps: {
      width: DESKTOP_SIZE_WIDTH,
      height: DESKTOP_SIZE_HEIGHT,
      sizes: '587',
    },
  })

  const { srcSet: tabletSize } = getNextImageProps({
    image: {
      src: activeAsset.overviewImage.src,
      alt: activeAsset.overviewImage.alt,
    },
    aspectRatio: `${TABLET_SIZE_WIDTH}:${TABLET_SIZE_HEIGHT}`,
    imageProps: {
      width: TABLET_SIZE_WIDTH,
      height: TABLET_SIZE_HEIGHT,
    },
  })

  const { srcSet: mobileSize, ...mobileRest } = getNextImageProps({
    image: {
      src: activeAsset.overviewImage.src,
      alt: activeAsset.overviewImage.alt,
    },
    aspectRatio: `${MOBILE_SIZE_WIDTH}:${MOBILE_SIZE_HEIGHT}`,
    imageProps: {
      width: MOBILE_SIZE_WIDTH,
      height: MOBILE_SIZE_HEIGHT,
    },
  })

  const MainImageComponent = () => (
    <StyledMainImage>
      <picture>
        <source
          width={DESKTOP_SIZE_WIDTH}
          height={DESKTOP_SIZE_HEIGHT}
          media={mediaQueries.mediaQueryDesktop}
          srcSet={desktopSize}
        />
        <source
          width={TABLET_SIZE_WIDTH}
          height={TABLET_SIZE_HEIGHT}
          // The small variant has a different breakpoint for tablet
          media={mediaQueries.mediaQueryTablet}
          srcSet={tabletSize}
        />
        <source width={MOBILE_SIZE_WIDTH} height={MOBILE_SIZE_HEIGHT} srcSet={mobileSize} />

        <img
          {...mobileRest}
          width={MOBILE_SIZE_WIDTH}
          height={MOBILE_SIZE_HEIGHT}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            aspectRatio: `${MOBILE_SIZE_WIDTH}/${MOBILE_SIZE_HEIGHT}`,
          }}
        />
      </picture>
    </StyledMainImage>
  )

  return (
    <Gallery>
      <MainImage>
        <MainAssetWrapper>
          <EnlargeButton onClick={() => openAssetInModal(activeAsset)}>
            <ButtonDescription
              show={
                activeAsset.__typename === 'StandardProduct3DAsset' ||
                activeAsset.__typename === 'StandardProductVirtualTourAsset'
              }
            >
              {galleryFullscreenButtonText}
            </ButtonDescription>
            <Icon.Fullscreen fill={colors.blue} width={20} height={20} />
          </EnlargeButton>

          {activeAsset.__typename === 'StandardProductImageAsset' && <MainImageComponent />}
          {activeAsset.__typename === 'StandardProductVideoAsset' && (
            <Video.Default
              videoId={activeAsset.videoId}
              videoType={activeAsset.videoType}
              stopWhenOutOfView
              autoplay={1}
            />
          )}
          {activeAsset.__typename === 'StandardProduct3DAsset' && <MainImageComponent />}
          {activeAsset.__typename === 'StandardProductVirtualTourAsset' && <MainImageComponent />}
        </MainAssetWrapper>
      </MainImage>
      {filteredItems && filteredItems.length > 0 && (
        <Assets>
          <AssetsWrapper>
            <Slider.Generic
              sliderRef={sliderRef}
              paginationAlignment="xy-centered"
              arrowStyle="thumbnail"
              id="gallery-preview-slider"
              {...sliderSettings}
            >
              {filteredItems.map((asset) => {
                return (
                  <AssetWrapper key={asset.id} onClick={() => setActiveAsset(asset)}>
                    <StyledSmallImage
                      {...asset.thumbnail}
                      src={asset.thumbnail?.src ?? ''}
                      alt={asset.thumbnail?.alt ?? ''}
                      width={123}
                      height={123}
                      aspectRatio="1:1"
                      style={{
                        objectFit: 'cover',
                        opacity:
                          asset.__typename === 'StandardProduct3DAsset' ||
                          asset.__typename === 'StandardProductVirtualTourAsset'
                            ? 0.32
                            : 1,
                      }}
                    />
                    {asset.__typename === 'StandardProductVideoAsset' && (
                      <StyledPlayButton>
                        <Media.PlayButton />
                      </StyledPlayButton>
                    )}
                    {asset.__typename === 'StandardProduct3DAsset' && (
                      <ThumbnailIconWrapper>
                        <Icon.Rotate360 width={37} height={37} />
                      </ThumbnailIconWrapper>
                    )}
                    {asset.__typename === 'StandardProductVirtualTourAsset' && (
                      <ThumbnailIconWrapper>
                        <Icon.Atom width={28} height={28} fill={colors.blue} />
                      </ThumbnailIconWrapper>
                    )}
                  </AssetWrapper>
                )
              })}
            </Slider.Generic>
          </AssetsWrapper>
        </Assets>
      )}
      {galleryItems && galleryItems.assets.length > 0 && (
        <Modal.Overlay
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          background="transparent"
          contentBackground="white"
          closeButtonInModalBody
          allowContentScroll={shouldModalContentScroll}
        >
          <MediaGallery.Default
            tabs={galleryItems.tabs}
            assets={galleryItems.assets}
            backToOverviewTitle={galleryItems.backToOverviewTitle}
            // Props adjustmets for the Assets in the gallery are not needed for the overview in the Gallery, as it is only an addition
            // we cast the type back to what it used to be, as the MediaGallery expects it to be of type Asset[]
            overviewAssets={items as Asset[]}
            activeFilter={activeGalleryTab}
            activeIndex={activeGalleryItem}
            updateShouldModalContentScroll={setShouldModalContentScroll}
          />
        </Modal.Overlay>
      )}
    </Gallery>
  )
}

export default GalleryPreview
