import React, { Suspense, useMemo } from 'react'
import styled from 'styled-components'
import { NextImage, theme } from '@damen/ui'

import {
  StandardProductDetails as StandardProductDetailsProps,
  StandardProductGalleryAsset,
  StandardProduct3DAsset,
  StandardProductVideoAsset,
} from '@/queries/schema.generated'
import type { ModalProps } from '@/components/UI/Modal/types'
import { Container } from '@/components/UI'
import StandardProductDetailsComponent from '@/components/StandardProductDetails'
import ModelRenderer from '@/components/StandardProductDetails/components/modelRenderer'
import type { ImageProps, VideoProps, CustomAssetProps as CustomGalleryAssetProps } from '@damen/ui/lib/cjs/components/MediaGallery/types'

const StyledContainer = styled(Container)`
  padding-top: ${theme.spacing.x2}px;
  padding-bottom: ${theme.spacing.x5}px;

  @media ${theme.mediaQueries.mediaQueryDesktop} {
    padding-top: 0;
    padding-bottom: ${theme.spacing.x10}px;
  }
`

const SuspenseWrapper = styled.div`
  aspect-ratio: 1591 / 1030;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 230px);
  width: 100%;
`

const StyledIframe = styled.iframe`
  border: none;
`

interface Props {
  content: StandardProductDetailsProps
  setOpenModal: (props: ModalProps) => void
}

interface StandardProductDetailsAssetProps {
  thumbnail: {
    src: string
    alt: string
    sizes: string
  }
  overviewImage: {
    src: string
    alt: string
    sizes: string
  }
  linkComponent?: React.ComponentType<{ children: React.ReactNode }>
  videoId?: StandardProductVideoAsset['videoId']
  videoType?: StandardProductVideoAsset['videoType']
  asset?: StandardProduct3DAsset['asset']
}

export type CustomAssetProps = Pick<StandardProductGalleryAsset, 'id' | 'title' | 'type' | '__typename'> &
  StandardProductDetailsAssetProps

const StandardProductDetails = ({ content, setOpenModal }: Props) => {
  const {
    product,
    description,
    options,
    keyFigures,
    usps,
    galleryItems,
    assets,
    overviewSectionId,
    configureLink,
    configureLinkText,
    configureText,
    downloadLinkText,
    downloadLink,
    downloadText,
    enableDirectProductSheetDownload,
    showMoreText,
    showLessText,
    fuelTypes,
    galleryFullscreenButtonText,
  } = content

  const mappedAssets = useMemo(() => {
    return {
      ...assets,
      items: assets?.items.map((item) => {
        const result: CustomAssetProps = {
          ...item,
          thumbnail: {
            src: item?.thumbnail,
            alt: item?.title,
            sizes: `${theme.legacyMediaQueries.lg} 200px, ${theme.legacyMediaQueries.md} 150px, 50vw`,
          },
          overviewImage: {
            src: item?.thumbnail,
            alt: item?.title,
            sizes: `${theme.legacyMediaQueries.lg} 600px, ${theme.legacyMediaQueries.md} 460px, 100vw`,
          },
        }
        return result
      }),
    }
  }, [assets])

  const mappedGalleryItems = useMemo(() => {
    return {
      ...galleryItems,
      assets: galleryItems?.assets.map((item) => {
        if (item.__typename === 'GalleryItem3DAsset') {
          const mappedItem = {
            ...item,
            category: 'asset3D',
            component: () => (
              <SuspenseWrapper>
                <Suspense fallback={<NextImage src={item.image} alt={item.alt} fill />}>
                  <ModelRenderer url={item.asset} />
                </Suspense>
              </SuspenseWrapper>
            ),
          }
          return mappedItem as CustomGalleryAssetProps
        }
        
        if (item.__typename === 'GalleryItemVirtualTour') {
          const mappedItem = {
            ...item,
            category: 'virtualTour',
            component: () => (
              <StyledIframe src={item.asset} width='100%' height='100%' />
            ),
          }
          return mappedItem as CustomGalleryAssetProps
        }

        if (item.__typename === 'GalleryItemImage') {
          return item as ImageProps
        }

        if (item.__typename === 'GalleryItemVideo') {
          return item as VideoProps
        }

        return item
      }),
    }
  }, [galleryItems])

  const configurationData = useMemo(() => {
    return {
      text: configureText,
      link: configureLink,
      linkText: configureLinkText,
    }
  }, [configureText, configureLink, configureLinkText])

  const downloadData = useMemo(() => {
    return {
      link: downloadLink,
      linkText: downloadLinkText,
      text: downloadText,
      enableDirectProductSheetDownload,
      product: product,
    }
  }, [downloadLink, downloadLinkText, downloadText, enableDirectProductSheetDownload, product])

  return (
    <StyledContainer>
      <StandardProductDetailsComponent
        overviewSectionId={overviewSectionId}
        title={product?.name}
        description={description}
        options={options}
        keyFigures={keyFigures}
        usps={usps}
        galleryItems={mappedGalleryItems}
        fuelTypes={fuelTypes}
        assets={mappedAssets}
        downloadData={downloadData}
        setOpenModal={setOpenModal}
        configuration={configurationData}
        showMoreText={showMoreText}
        showLessText={showLessText}
        galleryFullscreenButtonText={galleryFullscreenButtonText}
      />
    </StyledContainer>
  )
}

export default StandardProductDetails
