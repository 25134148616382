/* eslint-disable */
 // @ts-disable

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * A date string, such as 2007-12-03, compliant with the `full-date` format
   * outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for
   * representation of dates and times using the Gregorian calendar.
   */
  Date: { input: any; output: any; }
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type AboutBody = ArticleFeed | ArticleSlider | Banner | BodyContent | BowWave | Breadcrumb | Cta | Collage | CollageSecondary | DynamicImage | FaqIconCards | Highlights | ImageFullscreenSingle | LinkBlocks | MapGlobal | MultiColumn | NewsFeed | Numbers | ParallaxTiles | Referrals | Services | StepByStep | StoryTell | StoryTellDeepDive | TestimonialCards | Testimonials | TextWithImage | TextWithVideo | Tiles | VideoPanoramic;

export type AboutContent = {
  __typename?: 'AboutContent';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<AboutBody>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<AboutHeader>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
};

export type AboutHeader = HeaderPrimary | HeaderSecondary | HeaderSecondaryDeepDive | HeaderSecondaryNoImage | HeaderSecondaryText | HeaderSecondaryWithTabs | HeaderVideo;

export type AboutPage = {
  __typename?: 'AboutPage';
  content?: Maybe<AboutContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type AlgoliaArticleOverview = AlgoliaOverview & AlgoliaOverviewWithSearch & IPageComponent & {
  __typename?: 'AlgoliaArticleOverview';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  placeholderSearch?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaCharterProductOverview = AlgoliaOverview & IPageComponent & {
  __typename?: 'AlgoliaCharterProductOverview';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  defaultCardLabels?: Maybe<AlgoliaCharterProductOverviewDefaultCardLabels>;
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalResultsText?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaCharterProductOverviewDefaultCardLabels = {
  __typename?: 'AlgoliaCharterProductOverviewDefaultCardLabels';
  ctaText?: Maybe<Scalars['String']['output']>;
  downloadText?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaEventOverview = AlgoliaOverview & IPageComponent & {
  __typename?: 'AlgoliaEventOverview';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  defaultCardLabels?: Maybe<AlgoliaEventOverviewDefaultCardLabels>;
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  sortByOptions?: Maybe<Array<Maybe<AlgoliaSortBy>>>;
  sortByPlaceholder?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type AlgoliaEventOverviewDefaultCardLabels = {
  __typename?: 'AlgoliaEventOverviewDefaultCardLabels';
  dateText?: Maybe<Scalars['String']['output']>;
  externalPageText?: Maybe<Scalars['String']['output']>;
  internalPageText?: Maybe<Scalars['String']['output']>;
  locationText?: Maybe<Scalars['String']['output']>;
  marketsText?: Maybe<Scalars['String']['output']>;
};

export enum AlgoliaFilterComponentType {
  Checkbox = 'checkbox',
  Dropdown = 'dropdown'
}

export type AlgoliaGlobalSearch = {
  __typename?: 'AlgoliaGlobalSearch';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  searchtabs?: Maybe<Array<Maybe<AlgoliaGlobalSearchTabs>>>;
};

export type AlgoliaGlobalSearchAutosuggestOptions = {
  __typename?: 'AlgoliaGlobalSearchAutosuggestOptions';
  _uid: Scalars['String']['output'];
  buttonPrefixText?: Maybe<Scalars['String']['output']>;
  cardType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type AlgoliaGlobalSearchIndex = {
  __typename?: 'AlgoliaGlobalSearchIndex';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  indexName?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaGlobalSearchTabs = {
  __typename?: 'AlgoliaGlobalSearchTabs';
  _uid: Scalars['String']['output'];
  algoliaindex?: Maybe<Array<Maybe<AlgoliaGlobalSearchIndex>>>;
  autosuggestOptions?: Maybe<AlgoliaGlobalSearchAutosuggestOptions>;
  component: Scalars['String']['output'];
  enableAutosuggest?: Maybe<Scalars['Boolean']['output']>;
  filters?: Maybe<Array<Maybe<AlgoliaGlobalSearchfilter>>>;
  id: Scalars['ID']['output'];
  layoutOption?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfHitsPerPage?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaGlobalSearchfilter = {
  __typename?: 'AlgoliaGlobalSearchfilter';
  _uid: Scalars['String']['output'];
  collapseText?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  expandText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  labelFalse: Scalars['String']['output'];
  labelTrue: Scalars['String']['output'];
  showFalseValue?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaInStockProductOverview = AlgoliaOverview & IPageComponent & {
  __typename?: 'AlgoliaInStockProductOverview';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  defaultCardLabels?: Maybe<AlgoliaInStockProductOverviewDefaultCardLabels>;
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
};

export type AlgoliaInStockProductOverviewDefaultCardLabels = {
  __typename?: 'AlgoliaInStockProductOverviewDefaultCardLabels';
  ctaText?: Maybe<Scalars['String']['output']>;
  downloadText?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaNewsOverview = AlgoliaOverview & AlgoliaOverviewWithSearch & IPageComponent & {
  __typename?: 'AlgoliaNewsOverview';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  placeholderSearch?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaOverview = {
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaOverviewFilter = {
  __typename?: 'AlgoliaOverviewFilter';
  _uid?: Maybe<Scalars['String']['output']>;
  componentType?: Maybe<AlgoliaFilterComponentType>;
  filterType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  labelFalse?: Maybe<Scalars['String']['output']>;
  labelTrue?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  unit?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaOverviewWithSearch = {
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  placeholderSearch?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaProjectOverview = AlgoliaOverview & IPageComponent & {
  __typename?: 'AlgoliaProjectOverview';
  _uid: Scalars['String']['output'];
  category: Scalars['String']['output'];
  component: Scalars['String']['output'];
  defaultCardLabels?: Maybe<AlgoliaProjectOverviewDefaultCardLabels>;
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaProjectOverviewDefaultCardLabels = {
  __typename?: 'AlgoliaProjectOverviewDefaultCardLabels';
  contractorText?: Maybe<Scalars['String']['output']>;
  periodText?: Maybe<Scalars['String']['output']>;
  servicesText?: Maybe<Scalars['String']['output']>;
  shipyardText?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaShiprepairYardOverview = AlgoliaOverview & IPageComponent & {
  __typename?: 'AlgoliaShiprepairYardOverview';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  defaultCardLabels?: Maybe<AlgoliaShiprepairYardOverviewDefaultCardLabels>;
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  listViewText?: Maybe<Scalars['String']['output']>;
  mapViewText?: Maybe<Scalars['String']['output']>;
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaShiprepairYardOverviewDefaultCardLabels = {
  __typename?: 'AlgoliaShiprepairYardOverviewDefaultCardLabels';
  coordinates?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  maxLength?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaSortBy = {
  __typename?: 'AlgoliaSortBy';
  _uid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  option?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaSortBy4thSpecification = {
  __typename?: 'AlgoliaSortBy4thSpecification';
  _uid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  option?: Maybe<Scalars['String']['output']>;
  ranges?: Maybe<Array<Maybe<AlgoliaSortByRangeFilter>>>;
};

export type AlgoliaSortByRangeFilter = {
  __typename?: 'AlgoliaSortByRangeFilter';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaUsedProductOverview = AlgoliaOverview & IPageComponent & {
  __typename?: 'AlgoliaUsedProductOverview';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  defaultCardLabels?: Maybe<AlgoliaUsedProductOverviewDefaultCardLabels>;
  filters?: Maybe<Array<AlgoliaOverviewFilter>>;
  id: Scalars['ID']['output'];
  mobileFiltersTitle?: Maybe<Scalars['String']['output']>;
  noResultsText?: Maybe<Scalars['String']['output']>;
  noResultsTitle?: Maybe<Scalars['String']['output']>;
  placeholderResults?: Maybe<Scalars['String']['output']>;
  sortByOptions?: Maybe<Array<Maybe<AlgoliaSortBy>>>;
  sortByOptions4thSpecification?: Maybe<Array<Maybe<AlgoliaSortBy4thSpecification>>>;
  sortByPlaceholder?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalResultsText?: Maybe<Scalars['String']['output']>;
};

export type AlgoliaUsedProductOverviewDefaultCardLabels = {
  __typename?: 'AlgoliaUsedProductOverviewDefaultCardLabels';
  priceCategory?: Maybe<Scalars['String']['output']>;
};

export type AllIndexItems = ArticlesIndexItem | EventIndexItem | LocationIndexItem | ProductIndexItem | ProjectIndexItem | UsedProductIndexItem;

export type ArticleBody = {
  __typename?: 'ArticleBody';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  content?: Maybe<Array<Maybe<ArticleBodyContent>>>;
  id: Scalars['ID']['output'];
};

export type ArticleBodyContent = BodyContent | Image | Video;

export type ArticleDetailBody = ArticleBody | ArticleFeed | Breadcrumb | Cta | Newsletter | TextWithImage | TextWithVideo | VideoPanoramic;

export type ArticleDetailContent = {
  __typename?: 'ArticleDetailContent';
  _uid: Scalars['String']['output'];
  body?: Maybe<Array<Maybe<ArticleDetailBody>>>;
  component: Scalars['String']['output'];
  header?: Maybe<HeaderArticle>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
  tags?: Maybe<Tags>;
};

export type ArticleDetailPage = {
  __typename?: 'ArticleDetailPage';
  content?: Maybe<ArticleDetailContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ArticleFeed = {
  __typename?: 'ArticleFeed';
  _uid: Scalars['String']['output'];
  articles?: Maybe<Array<Maybe<ArticleFeedItem>>>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  tagsFilter?: Maybe<Tags>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ArticleFeedItem = {
  __typename?: 'ArticleFeedItem';
  _uid?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  readTime?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Tags>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ArticleHeader = HeaderSecondary | HeaderSecondaryDeepDive | HeaderSecondaryNoImage | HeaderSecondaryText | HeaderSecondaryWithTabs | HeaderSplit;

export type ArticleLongForm = {
  __typename?: 'ArticleLongForm';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<ArticleLongFormContent>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<ArticleHeader>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
};

export type ArticleLongFormContent = BodyContent | BowWave | Cta | Image | Testimonials | Video;

export type ArticleOverview = {
  __typename?: 'ArticleOverview';
  content?: Maybe<ArticleOverviewContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ArticleOverviewBody = AlgoliaArticleOverview | Breadcrumb | Cta | Newsletter | Stream | Unknown;

export type ArticleOverviewContent = {
  __typename?: 'ArticleOverviewContent';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<ArticleOverviewBody>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<HeaderSecondaryBase>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
};

export type ArticlePage = {
  __typename?: 'ArticlePage';
  content?: Maybe<ArticlePageContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ArticlePageContent = ArticleLongForm | ArticleShortForm;

export type ArticleShortForm = {
  __typename?: 'ArticleShortForm';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<ArticleShortFormContent>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<ArticleHeader>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
};

export type ArticleShortFormContent = BodyContent | BowWave | Breadcrumb | Collage | FaqIconCards | Testimonials | TextWithImage | TextWithVideo;

export type ArticleSlide = BaseSlide & {
  __typename?: 'ArticleSlide';
  _uid: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ArticleSlider = {
  __typename?: 'ArticleSlider';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  category?: Maybe<ArticleSliderContent>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ArticleSliderContent = {
  __typename?: 'ArticleSliderContent';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  slides: Array<ArticleSlide>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ArticlesIndexItem = {
  __typename?: 'ArticlesIndexItem';
  createdAt: Scalars['Date']['output'];
  description?: Maybe<Scalars['String']['output']>;
  formattedCreatedAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  indexableContent?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  objectID: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
  tags?: Maybe<ArticlesIndexItemTags>;
  title: Scalars['String']['output'];
  type: ArticlesIndexItemType;
};

export type ArticlesIndexItemTags = ITags & {
  __typename?: 'ArticlesIndexItemTags';
  company?: Maybe<Array<Scalars['String']['output']>>;
  market?: Maybe<Array<Scalars['String']['output']>>;
  pillar?: Maybe<Array<Scalars['String']['output']>>;
  product?: Maybe<Array<Scalars['String']['output']>>;
};

export enum ArticlesIndexItemType {
  Article = 'article',
  News = 'news'
}

export type Asset = {
  __typename?: 'Asset';
  id: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: GalleryItemType;
};

export type AssetVideoType = {
  __typename?: 'AssetVideoType';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Assets = {
  __typename?: 'Assets';
  items: Array<Maybe<Asset>>;
  title: Scalars['String']['output'];
};

export type Banner = {
  __typename?: 'Banner';
  _uid: Scalars['String']['output'];
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type BaseSlide = {
  _uid: Scalars['String']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  title: Scalars['String']['output'];
};

export type BodyContent = {
  __typename?: 'BodyContent';
  _uid: Scalars['String']['output'];
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intro?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
};

export type BowWave = {
  __typename?: 'BowWave';
  _uid: Scalars['String']['output'];
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type BrandCard = {
  __typename?: 'BrandCard';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  size?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type BrandFeed = {
  __typename?: 'BrandFeed';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<BrandCard>>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<BreadcrumbItem>>>;
  mobileBackground?: Maybe<Scalars['String']['output']>;
};

export type BreadcrumbItem = {
  __typename?: 'BreadcrumbItem';
  _uid: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  text?: Maybe<Scalars['String']['output']>;
};

export type BreadcrumbSmall = {
  __typename?: 'BreadcrumbSmall';
  link?: Maybe<StoryblokLink>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Cta = {
  __typename?: 'CTA';
  _uid: Scalars['String']['output'];
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  componentBackground?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CampaignSmallForm = IPardotHandler & {
  __typename?: 'CampaignSmallForm';
  _uid: Scalars['String']['output'];
  buttonText?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  email?: Maybe<InputField>;
  error?: Maybe<ModalError>;
  firstname?: Maybe<InputField>;
  genericValidations?: Maybe<GenericFormFieldValidations>;
  id: Scalars['ID']['output'];
  lastname?: Maybe<InputField>;
  pardotEndpoint?: Maybe<Scalars['String']['output']>;
  privacy?: Maybe<InputField>;
  subscribe?: Maybe<InputField>;
  subtitle?: Maybe<Scalars['String']['output']>;
  success?: Maybe<ModalSuccess>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CampaignSmallFormPayload = {
  currentUrl?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstname?: InputMaybe<Scalars['String']['input']>;
  gRecaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  gaClientId?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  privacyStatementAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  retryCount?: InputMaybe<Scalars['Int']['input']>;
  subscribeNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CardAlternative = {
  __typename?: 'CardAlternative';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  date?: Maybe<Scalars['Date']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  tags?: Maybe<Tags>;
  text?: Maybe<Scalars['String']['output']>;
};

export type CardFeedAlternative = {
  __typename?: 'CardFeedAlternative';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<CardAlternative>>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ChatAvailabilityResponse = {
  __typename?: 'ChatAvailabilityResponse';
  isAvailable: Scalars['Boolean']['output'];
};

export type ChatEnabledInput = {
  id: Scalars['String']['input'];
};

export type ChatEnabledResponse = {
  __typename?: 'ChatEnabledResponse';
  isAvailable: Scalars['Boolean']['output'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  sender: ChatMessageSender;
  type: ChatMessageType;
};

export enum ChatMessageSender {
  Damen = 'Damen',
  User = 'User'
}

export enum ChatMessageType {
  ChatMessage = 'chatMessage',
  EndChat = 'endChat',
  EstablishedChat = 'establishedChat'
}

export type ChatMessagesInput = {
  sequenceId?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
};

export type ChatMessagesResponse = {
  __typename?: 'ChatMessagesResponse';
  messages?: Maybe<Array<ChatMessage>>;
};

export type Collage = {
  __typename?: 'Collage';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageLarge?: Maybe<ImageType>;
  imageSmall?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  spacing?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type CollageSecondary = {
  __typename?: 'CollageSecondary';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageLarge?: Maybe<ImageType>;
  imageMedium?: Maybe<ImageType>;
  imageSmall?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  spacing?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoType?: Maybe<Scalars['String']['output']>;
};

export type ContactContact = {
  __typename?: 'ContactContact';
  _uid: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  linkedin?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  subline1?: Maybe<Scalars['String']['output']>;
  subline2?: Maybe<Scalars['String']['output']>;
};

export type ContactFormPayload = {
  company?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  countryOfOperation?: InputMaybe<Scalars['String']['input']>;
  currentUrl?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstname?: InputMaybe<Scalars['String']['input']>;
  gRecaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  gaClientId?: InputMaybe<Scalars['String']['input']>;
  googleClickId?: InputMaybe<Scalars['String']['input']>;
  interestedIn?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  retryCount?: InputMaybe<Scalars['Int']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subscribeNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ContactFormType {
  Default = 'default',
  Simple = 'simple'
}

export type ContactInfoCompany = {
  __typename?: 'ContactInfoCompany';
  _uid: Scalars['String']['output'];
  address?: Maybe<ContactInfoCompanyAddress>;
  component: Scalars['String']['output'];
  contactInfo?: Maybe<ContactInfoCompanyContact>;
  formattedLatitude?: Maybe<Scalars['String']['output']>;
  formattedLongitude?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  location: Location;
  locationImage?: Maybe<Scalars['String']['output']>;
  locationImageTablet?: Maybe<Scalars['String']['output']>;
  mapLinkText?: Maybe<Scalars['String']['output']>;
  postalAddress?: Maybe<ContactInfoCompanyAddress>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ContactInfoCompanyAddress = {
  __typename?: 'ContactInfoCompanyAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type ContactInfoCompanyContact = {
  __typename?: 'ContactInfoCompanyContact';
  email?: Maybe<ContactInfoCompanyContactDetails>;
  fax?: Maybe<ContactInfoCompanyContactDetails>;
  label?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<ContactInfoCompanyContactDetails>;
};

export type ContactInfoCompanyContactDetails = {
  __typename?: 'ContactInfoCompanyContactDetails';
  label?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Contacts = {
  __typename?: 'Contacts';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  contacts: Array<ContactContact>;
  id: Scalars['ID']['output'];
  initialContacts?: Maybe<Scalars['Int']['output']>;
  showLessText?: Maybe<Scalars['String']['output']>;
  showMoreText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Content = {
  __typename?: 'Content';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<ContentBody>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<ContentHeader>;
  seo?: Maybe<SeoData>;
  tags?: Maybe<Tags>;
};

export type ContentBody = AlgoliaCharterProductOverview | AlgoliaEventOverview | AlgoliaGlobalSearch | AlgoliaInStockProductOverview | AlgoliaProjectOverview | AlgoliaShiprepairYardOverview | AlgoliaUsedProductOverview | ArticleFeed | ArticleSlider | Banner | BodyContent | BowWave | Breadcrumb | Cta | CampaignSmallForm | Collage | CollageSecondary | ContactInfoCompany | Contacts | Downloads | DynamicImage | EventDetails | EventFeed | ExpertiseSlider | FamilyModel | FaqIconCards | FullscreenSlider | Gallery | Highlights | Image | ImageFullscreenSingle | IntegratedServices | LinkBlocks | MapGlobal | MultiColumn | NewsFeed | Numbers | ParallaxTiles | PardotForm | Partners | ProductDetails | ProductList | ProductQuickView | ProductSlider | ProjectDetails | QuickNav | Referrals | ServiceBanner | Services | StandardProductCompare | StandardProductDetails | StandardProductPropositionHeader | StepByStep | StickyNavigation | StoryTell | StoryTellDeepDive | Stream | StreamThumbnails | Table | TableComparison | TableWithRows | Tabs | TabsFamily | TestimonialCards | Testimonials | TextWithImage | TextWithVideo | Tiles | Unknown | UsedProductDetails | Video | VideoCenter | VideoFeed | VideoPanoramic;

export type ContentHeader = HeaderError | HeaderHome | HeaderPrimary | HeaderSecondary | HeaderSecondaryDeepDive | HeaderSecondaryNoImage | HeaderSecondaryText | HeaderSecondaryWithTabs | HeaderSplit | HeaderVideo | Unknown;

export type ContentMedia = {
  __typename?: 'ContentMedia';
  image?: Maybe<ImageType>;
  video?: Maybe<VideoType>;
};

export type ContentPage = IPage & {
  __typename?: 'ContentPage';
  content?: Maybe<Content>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type CuratedInsightsCard = {
  __typename?: 'CuratedInsightsCard';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  size?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoType?: Maybe<Scalars['String']['output']>;
};

export type CuratedInsightsRow = {
  __typename?: 'CuratedInsightsRow';
  _uid: Scalars['String']['output'];
  cards?: Maybe<Array<CuratedInsightsCard>>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type DeepDiveItem = BodyContent | BowWave | Collage | Image | ImageFullscreen | MultiColumn | StoryTell | StoryTellDeepDive | TextWithImage | TextWithVideo | Video;

export type DisclaimerContent = {
  __typename?: 'DisclaimerContent';
  text?: Maybe<Scalars['String']['output']>;
};

export type DonationFormPayload = {
  address: Scalars['String']['input'];
  amount: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  contactMe?: InputMaybe<Scalars['Boolean']['input']>;
  contribution: Scalars['String']['input'];
  currentUrl?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  gRecaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  gaClientId?: InputMaybe<Scalars['String']['input']>;
  googleClickId?: InputMaybe<Scalars['String']['input']>;
  invoice: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  otherAmount?: InputMaybe<Scalars['Float']['input']>;
  retryCount?: InputMaybe<Scalars['Int']['input']>;
  willTransfer: Scalars['Boolean']['input'];
};

export type DownloadItem = {
  __typename?: 'DownloadItem';
  downloadText?: Maybe<Scalars['String']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Downloads = {
  __typename?: 'Downloads';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  downloads?: Maybe<Array<Maybe<DownloadItem>>>;
  id: Scalars['ID']['output'];
  showLess?: Maybe<Scalars['String']['output']>;
  showMore?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DynamicImage = {
  __typename?: 'DynamicImage';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  items?: Maybe<Array<DynamicImageItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type DynamicImageItem = {
  __typename?: 'DynamicImageItem';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type EmailTranslations = {
  __typename?: 'EmailTranslations';
  id: Scalars['ID']['output'];
  subject?: Maybe<Scalars['String']['output']>;
};

export type EndChatInput = {
  sequenceId?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
};

export type EventDetails = {
  __typename?: 'EventDetails';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: ImageType;
  introduction: Scalars['JSON']['output'];
  introductionTitle: Scalars['String']['output'];
  program?: Maybe<Scalars['JSON']['output']>;
  programText?: Maybe<Scalars['JSON']['output']>;
  programTitle: Scalars['String']['output'];
  sidebar?: Maybe<EventDetailsAside>;
  websiteLink?: Maybe<StoryblokLink>;
  websiteLinkText?: Maybe<Scalars['String']['output']>;
};

export type EventDetailsAside = {
  __typename?: 'EventDetailsAside';
  additionalInfo?: Maybe<EventDetailsTitleWithText>;
  booth?: Maybe<EventDetailsTitleWithText>;
  date?: Maybe<EventDetailsTitleWithText>;
  image?: Maybe<ImageType>;
  location?: Maybe<EventDetailsLocation>;
  title: Scalars['String']['output'];
};

export type EventDetailsLocation = {
  __typename?: 'EventDetailsLocation';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EventDetailsTitleWithText = {
  __typename?: 'EventDetailsTitleWithText';
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EventFeed = {
  __typename?: 'EventFeed';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  eventItems: Array<EventFeedItem>;
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type EventFeedDate = {
  __typename?: 'EventFeedDate';
  day: Scalars['Int']['output'];
  month: Scalars['String']['output'];
};

export type EventFeedItem = {
  __typename?: 'EventFeedItem';
  date: EventFeedDate;
  id: Scalars['ID']['output'];
  subline?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type EventIndexItem = {
  __typename?: 'EventIndexItem';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['Date']['output'];
  formattedDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  markets?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  objectID: Scalars['ID']['output'];
  startDate: Scalars['Date']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ExpertiseSlider = {
  __typename?: 'ExpertiseSlider';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<ExpertiseSliderItem>>;
  label?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ExpertiseSliderItem = BaseSlide & {
  __typename?: 'ExpertiseSliderItem';
  _uid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  title: Scalars['String']['output'];
};

export type FaqItem = {
  __typename?: 'FAQItem';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Family = {
  __typename?: 'Family';
  content?: Maybe<FamilyContent>;
  /** @deprecated Field no longer supported */
  family: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Field no longer supported */
  range: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type FamilyContent = {
  __typename?: 'FamilyContent';
  _uid?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Scalars['String']['output']>;
  enableDirectProductSheetDownload?: Maybe<Scalars['Boolean']['output']>;
  header?: Maybe<FamilyHeader>;
  headerTabs?: Maybe<HeaderTabs>;
  id: Scalars['ID']['output'];
  models?: Maybe<Array<FamilyModel>>;
  overview?: Maybe<Array<Maybe<ContentBody>>>;
  seo?: Maybe<SeoData>;
  tags?: Maybe<Tags>;
};

export type FamilyHeader = HeaderSecondary | HeaderSecondaryDeepDive | HeaderSecondaryNoImage | HeaderSecondaryText | HeaderSecondaryWithTabs | HeaderVideo;

export type FamilyModel = {
  __typename?: 'FamilyModel';
  _uid?: Maybe<Scalars['String']['output']>;
  availableOptionsText?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  downloadLink?: Maybe<StoryblokLink>;
  downloadText?: Maybe<Scalars['String']['output']>;
  family?: Maybe<Scalars['String']['output']>;
  highlights?: Maybe<Array<Specification>>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  images?: Maybe<Array<ImageType>>;
  labels?: Maybe<Array<Tag>>;
  productDetailLink?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Scalars['String']['output']>;
  readMoreText?: Maybe<Scalars['String']['output']>;
  salesforceName?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  title?: Maybe<Scalars['String']['output']>;
};

export type FamilyPage = ContentPage | Family;

export type FaqBody = BodyContent | FaqIconCards;

export type FaqContent = {
  __typename?: 'FaqContent';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<FaqBody>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<FaqHeader>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
};

export type FaqHeader = HeaderSecondary | HeaderSecondaryDeepDive | HeaderSecondaryNoImage | HeaderSecondaryText | HeaderSecondaryWithTabs | HeaderSplit | HeaderVideo;

export type FaqIconCardItem = {
  __typename?: 'FaqIconCardItem';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  questions?: Maybe<Array<FaqItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type FaqIconCards = {
  __typename?: 'FaqIconCards';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  cards?: Maybe<Array<FaqIconCardItem>>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type FaqPage = {
  __typename?: 'FaqPage';
  content?: Maybe<FaqContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type FeedbackFormPayload = {
  company: Scalars['String']['input'];
  currentUrl?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  gRecaptchaResponse?: InputMaybe<Scalars['String']['input']>;
  gaClientId?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  message: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  retryCount?: InputMaybe<Scalars['Int']['input']>;
  subscribeNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Footer = {
  __typename?: 'Footer';
  content: FooterContent;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type FooterContent = {
  __typename?: 'FooterContent';
  _uid?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  legal: FooterLegal;
  newsletter?: Maybe<FooterNewsletter>;
  primaryLinks: Array<Maybe<FooterLink>>;
  screenreaderTitle: Scalars['String']['output'];
  secondaryLinks: Array<Maybe<FooterLink>>;
  socialLinks: Array<Maybe<FooterSocialLink>>;
  subTitle: Scalars['String']['output'];
};

export type FooterLegal = {
  __typename?: 'FooterLegal';
  copyright: Scalars['String']['output'];
  disclaimer: FooterLink;
  privacy: FooterLink;
  sitemap: FooterLink;
  termsAndConditions: FooterLink;
};

export type FooterLink = {
  __typename?: 'FooterLink';
  label: Scalars['String']['output'];
  link: StoryblokLink;
};

export type FooterNewsletter = {
  __typename?: 'FooterNewsletter';
  label: Scalars['String']['output'];
  placeholder: Scalars['String']['output'];
};

export type FooterSocialLink = {
  __typename?: 'FooterSocialLink';
  link: StoryblokLink;
  type: Scalars['String']['output'];
};

export enum FormRequestType {
  CampaignSmallForm = 'campaignSmallForm',
  CharterProductQuote = 'charterProductQuote',
  Contact = 'contact',
  DepartmentContact = 'departmentContact',
  DepartmentNewsletter = 'departmentNewsletter',
  Download = 'download',
  Newsletter = 'newsletter',
  Quote = 'quote',
  UsedProductQuote = 'usedProductQuote'
}

export type FullscreenSlide = BaseSlide & {
  __typename?: 'FullscreenSlide';
  _uid: Scalars['String']['output'];
  color?: Maybe<Scalars['String']['output']>;
  deepDive?: Maybe<Array<DeepDiveItem>>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type FullscreenSlider = {
  __typename?: 'FullscreenSlider';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slides: Array<FullscreenSlide>;
  spacing?: Maybe<Scalars['String']['output']>;
};

export type Gallery = {
  __typename?: 'Gallery';
  _uid: Scalars['String']['output'];
  assets?: Maybe<Assets>;
  component: Scalars['String']['output'];
  galleryItems?: Maybe<GalleryItems>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type GalleryItem3DAsset = {
  __typename?: 'GalleryItem3DAsset';
  alt: Scalars['String']['output'];
  asset: Scalars['String']['output'];
  category: GalleryItemFilter;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  type: GalleryItemType;
};

export enum GalleryItemFilter {
  All = 'all',
  Asset3D = 'asset3D',
  Image = 'image',
  Image3D = 'image3D',
  Video = 'video',
  VirtualTour = 'virtualTour'
}

export type GalleryItemImage = {
  __typename?: 'GalleryItemImage';
  alt: Scalars['String']['output'];
  caption?: Maybe<Scalars['String']['output']>;
  category: GalleryItemFilter;
  id: Scalars['ID']['output'];
  type: GalleryItemType;
  url: Scalars['String']['output'];
};

export type GalleryItemItems = GalleryItem3DAsset | GalleryItemImage | GalleryItemVideo | GalleryItemVirtualTour;

export enum GalleryItemType {
  Asset3D = 'asset3D',
  Image = 'image',
  Image3D = 'image3D',
  Video = 'video',
  VirtualTour = 'virtualTour'
}

export type GalleryItemVideo = {
  __typename?: 'GalleryItemVideo';
  category: GalleryItemFilter;
  id: Scalars['ID']['output'];
  type: GalleryItemType;
  videoId: Scalars['String']['output'];
  videoType: GalleryVideoType;
};

export type GalleryItemVirtualTour = {
  __typename?: 'GalleryItemVirtualTour';
  alt: Scalars['String']['output'];
  asset: Scalars['String']['output'];
  category: GalleryItemFilter;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  type: GalleryItemType;
};

export type GalleryItems = {
  __typename?: 'GalleryItems';
  assets: Array<Maybe<GalleryItemItems>>;
  backToOverviewTitle: Scalars['String']['output'];
  tabs: Array<Maybe<GalleryTabs>>;
};

export type GalleryTabs = {
  __typename?: 'GalleryTabs';
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  type: GalleryItemFilter;
};

export enum GalleryVideoType {
  Vimeo = 'vimeo',
  Youtube = 'youtube'
}

export type GenericFormFieldValidations = {
  __typename?: 'GenericFormFieldValidations';
  containsUrl?: Maybe<Scalars['String']['output']>;
};

export type HeaderArticle = {
  __typename?: 'HeaderArticle';
  _uid?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  breadcrumb?: Maybe<BreadcrumbSmall>;
  component?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  intro?: Maybe<Scalars['String']['output']>;
  readTime?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderCuratedInsights = {
  __typename?: 'HeaderCuratedInsights';
  _uid: Scalars['String']['output'];
  breadcrumb?: Maybe<BreadcrumbSmall>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  rows?: Maybe<Array<CuratedInsightsRow>>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderError = {
  __typename?: 'HeaderError';
  _uid: Scalars['String']['output'];
  breadcrumb?: Maybe<BreadcrumbSmall>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type HeaderHome = {
  __typename?: 'HeaderHome';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  message: Scalars['String']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderPrimary = {
  __typename?: 'HeaderPrimary';
  _uid: Scalars['String']['output'];
  breadcrumb?: Maybe<BreadcrumbSmall>;
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  sustainableMark?: Maybe<Popover>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderSecondary = HeaderSecondaryBase & {
  __typename?: 'HeaderSecondary';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  breadcrumb?: Maybe<BreadcrumbSmall>;
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  deepDive?: Maybe<Array<DeepDiveItem>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  overlayColor?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  tabs?: Maybe<Array<HeaderSecondaryTab>>;
  tag?: Maybe<Tag>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderSecondaryBase = {
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  breadcrumb?: Maybe<BreadcrumbSmall>;
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  deepDive?: Maybe<Array<DeepDiveItem>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  sustainableMark?: Maybe<Popover>;
  tabs?: Maybe<Array<HeaderSecondaryTab>>;
  tag?: Maybe<Tag>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderSecondaryDeepDive = HeaderSecondaryBase & {
  __typename?: 'HeaderSecondaryDeepDive';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  breadcrumb?: Maybe<BreadcrumbSmall>;
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  deepDive?: Maybe<Array<DeepDiveItem>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  overlayColor?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  tabs?: Maybe<Array<HeaderSecondaryTab>>;
  tag?: Maybe<Tag>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderSecondaryNoImage = HeaderSecondaryBase & {
  __typename?: 'HeaderSecondaryNoImage';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  breadcrumb?: Maybe<BreadcrumbSmall>;
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  deepDive?: Maybe<Array<DeepDiveItem>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  sustainableMark?: Maybe<Popover>;
  tabs?: Maybe<Array<HeaderSecondaryTab>>;
  tag?: Maybe<Tag>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderSecondaryTab = {
  __typename?: 'HeaderSecondaryTab';
  handle?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type HeaderSecondaryText = HeaderSecondaryBase & {
  __typename?: 'HeaderSecondaryText';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  breadcrumb?: Maybe<BreadcrumbSmall>;
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  deepDive?: Maybe<Array<DeepDiveItem>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  overlayColor?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  tabs?: Maybe<Array<HeaderSecondaryTab>>;
  tag?: Maybe<Tag>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderSecondaryWithTabs = HeaderSecondaryBase & {
  __typename?: 'HeaderSecondaryWithTabs';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  breadcrumb?: Maybe<BreadcrumbSmall>;
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  deepDive?: Maybe<Array<DeepDiveItem>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  overlayColor?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  tabs?: Maybe<Array<HeaderSecondaryTab>>;
  tag?: Maybe<Tag>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderSplit = {
  __typename?: 'HeaderSplit';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  breadcrumb?: Maybe<BreadcrumbSmall>;
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  sustainableMark?: Maybe<Popover>;
  title?: Maybe<Scalars['String']['output']>;
};

export type HeaderTabs = {
  __typename?: 'HeaderTabs';
  background?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type HeaderVideo = {
  __typename?: 'HeaderVideo';
  _uid: Scalars['String']['output'];
  buttonType?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  video?: Maybe<AssetVideoType>;
};

export type Health = {
  __typename?: 'Health';
  status: HealthStatus;
};

export enum HealthStatus {
  Healthy = 'Healthy',
  Unhealthy = 'Unhealthy'
}

export type Highlight = {
  __typename?: 'Highlight';
  img?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Highlights = {
  __typename?: 'Highlights';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Highlight>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type IPage = {
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type IPageComponent = {
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type IPardotHandler = {
  pardotEndpoint?: Maybe<Scalars['String']['output']>;
};

export type ITags = {
  company?: Maybe<Array<Scalars['String']['output']>>;
  market?: Maybe<Array<Scalars['String']['output']>>;
  pillar?: Maybe<Array<Scalars['String']['output']>>;
  product?: Maybe<Array<Scalars['String']['output']>>;
};

export type Image = {
  __typename?: 'Image';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
};

export type ImageFullscreen = {
  __typename?: 'ImageFullscreen';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: Array<ImageFullscreenItem>;
};

export type ImageFullscreenItem = {
  __typename?: 'ImageFullscreenItem';
  img?: Maybe<ImageType>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ImageFullscreenSingle = {
  __typename?: 'ImageFullscreenSingle';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  spacing?: Maybe<Scalars['String']['output']>;
};

export type ImageType = {
  __typename?: 'ImageType';
  alt?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum IndexNameV2 {
  Articles = 'Articles',
  Global = 'Global',
  News = 'News',
  Press = 'Press'
}

export type InputField = {
  __typename?: 'InputField';
  error?: Maybe<Scalars['String']['output']>;
  extraLabel?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  placeholder?: Maybe<Scalars['String']['output']>;
  validation?: Maybe<Scalars['String']['output']>;
};

export type InsightsBody = ArticleFeed | BowWave | Breadcrumb | Cta | NewsFeed | VideoCenter;

export type InsightsContent = {
  __typename?: 'InsightsContent';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<InsightsBody>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<HeaderCuratedInsights>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
};

export type InsightsPage = {
  __typename?: 'InsightsPage';
  content?: Maybe<InsightsContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type IntegratedServices = {
  __typename?: 'IntegratedServices';
  component: Scalars['String']['output'];
  highlight?: Maybe<Array<IntegratedServicesHighlight>>;
  id: Scalars['ID']['output'];
  image: ImageType;
  linkText: Scalars['String']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type IntegratedServicesHighlight = {
  __typename?: 'IntegratedServicesHighlight';
  hotspots?: Maybe<Array<Maybe<IntegratedServicesHotspotWithHighlight>>>;
  id: Scalars['ID']['output'];
  image: ImageType;
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type IntegratedServicesHotspot = {
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationX: Scalars['String']['output'];
  locationY: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  video: AssetVideoType;
};

export type IntegratedServicesHotspotWithDetails = IntegratedServicesHotspot & {
  __typename?: 'IntegratedServicesHotspotWithDetails';
  color: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationX: Scalars['String']['output'];
  locationY: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  video: AssetVideoType;
};

export type IntegratedServicesHotspotWithHighlight = IntegratedServicesHotspot & {
  __typename?: 'IntegratedServicesHotspotWithHighlight';
  color: Scalars['String']['output'];
  highlight?: Maybe<Array<Maybe<IntegratedServicesSecondHighlight>>>;
  id: Scalars['ID']['output'];
  locationX: Scalars['String']['output'];
  locationY: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  video: AssetVideoType;
};

export type IntegratedServicesSecondHighlight = {
  __typename?: 'IntegratedServicesSecondHighlight';
  hotspots?: Maybe<Array<Maybe<IntegratedServicesHotspotWithDetails>>>;
  id: Scalars['ID']['output'];
  image: ImageType;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type InterestOption = {
  __typename?: 'InterestOption';
  activeContent?: Maybe<InterestOptionActiveContent>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type InterestOptionActiveContent = {
  __typename?: 'InterestOptionActiveContent';
  contact?: Maybe<ContactContact>;
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type KeyFigures = {
  __typename?: 'KeyFigures';
  columns?: Maybe<Array<KeyFiguresColumn>>;
  title: Scalars['String']['output'];
};

export type KeyFiguresColumn = {
  __typename?: 'KeyFiguresColumn';
  items: Array<Specification>;
  title: Scalars['String']['output'];
};

export type Link = {
  __typename?: 'Link';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link: StoryblokLink;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LinkBlock = LinkBlockImage | LinkBlockList;

export type LinkBlockImage = {
  __typename?: 'LinkBlockImage';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LinkBlockItem = {
  __typename?: 'LinkBlockItem';
  _uid: Scalars['String']['output'];
  component?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
};

export type LinkBlockList = {
  __typename?: 'LinkBlockList';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  links?: Maybe<Array<LinkBlockItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LinkBlocks = {
  __typename?: 'LinkBlocks';
  _uid: Scalars['String']['output'];
  blocks?: Maybe<Array<LinkBlock>>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LinkDetail = {
  __typename?: 'LinkDetail';
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type LinkWrapper = {
  __typename?: 'LinkWrapper';
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
};

export type Location = {
  __typename?: 'Location';
  _uid?: Maybe<Scalars['String']['output']>;
  address?: Maybe<LocationAddress>;
  component?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Array<LocationContactDetails>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  latitude: Scalars['Float']['output'];
  link?: Maybe<LinkWrapper>;
  longitude: Scalars['Float']['output'];
  mapsLocation?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalAddress?: Maybe<LocationPostalAddress>;
  timezone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<LocationType>>;
};

export type LocationAddress = {
  __typename?: 'LocationAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type LocationContactDetails = {
  __typename?: 'LocationContactDetails';
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type LocationFacilities = {
  __typename?: 'LocationFacilities';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type LocationIndexItem = {
  __typename?: 'LocationIndexItem';
  component?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  facilities?: Maybe<Array<LocationFacilities>>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  mapsImage?: Maybe<Scalars['String']['output']>;
  maxBeam?: Maybe<Scalars['Float']['output']>;
  maxLength?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  objectID: Scalars['ID']['output'];
  types?: Maybe<Array<LocationType>>;
};

export type LocationPostalAddress = {
  __typename?: 'LocationPostalAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type LocationType = {
  __typename?: 'LocationType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};

export type MailContent = {
  __typename?: 'MailContent';
  body?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
};

export type MapGlobal = {
  __typename?: 'MapGlobal';
  _uid: Scalars['String']['output'];
  buttonText?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  prefilteredLocations?: Maybe<Scalars['String']['output']>;
  spacing?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  robots?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ModalContact = {
  __typename?: 'ModalContact';
  content?: Maybe<ModalContactContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalContactContent = IPardotHandler & {
  __typename?: 'ModalContactContent';
  _uid: Scalars['String']['output'];
  buttonText?: Maybe<Scalars['String']['output']>;
  company?: Maybe<InputField>;
  component?: Maybe<Scalars['String']['output']>;
  contactDetails?: Maybe<ModalContactDetails>;
  country?: Maybe<InputField>;
  countryOfOperation?: Maybe<InputField>;
  disclaimer?: Maybe<DisclaimerContent>;
  email?: Maybe<InputField>;
  enableCreateProspect: Scalars['Boolean']['output'];
  error?: Maybe<ModalError>;
  firstname?: Maybe<InputField>;
  formType: ContactFormType;
  genericValidations?: Maybe<GenericFormFieldValidations>;
  id: Scalars['ID']['output'];
  interest?: Maybe<InputField>;
  interestOptions?: Maybe<Array<InterestOption>>;
  lastname?: Maybe<InputField>;
  pardotEndpoint?: Maybe<Scalars['String']['output']>;
  phonenumber?: Maybe<InputField>;
  showBowWave: Scalars['Boolean']['output'];
  subscribe?: Maybe<InputField>;
  subtitle?: Maybe<Scalars['String']['output']>;
  success?: Maybe<ModalSuccess>;
  textArea?: Maybe<InputField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ModalContactDetails = {
  __typename?: 'ModalContactDetails';
  address?: Maybe<ModalContactDetailsField>;
  email?: Maybe<ModalContactDetailsField>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  phonenumber?: Maybe<ModalContactDetailsField>;
  seoData?: Maybe<ModalContactDetailsSeo>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ModalContactDetailsField = {
  __typename?: 'ModalContactDetailsField';
  label?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ModalContactDetailsSeo = {
  __typename?: 'ModalContactDetailsSeo';
  companyLogo?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
};

export type ModalDepartmentAddressField = {
  __typename?: 'ModalDepartmentAddressField';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type ModalDepartmentContact = {
  __typename?: 'ModalDepartmentContact';
  content?: Maybe<ModalDepartmentContactContent>;
  formName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalDepartmentContactContent = IPardotHandler & {
  __typename?: 'ModalDepartmentContactContent';
  _uid: Scalars['String']['output'];
  buttonText?: Maybe<Scalars['String']['output']>;
  company?: Maybe<InputField>;
  component?: Maybe<Scalars['String']['output']>;
  country?: Maybe<InputField>;
  countryOfOperation?: Maybe<InputField>;
  departmentContactDetails?: Maybe<ModalDepartmentContactDetails>;
  disclaimer?: Maybe<DisclaimerContent>;
  email?: Maybe<InputField>;
  enableCreateProspect: Scalars['Boolean']['output'];
  error?: Maybe<ModalError>;
  firstname?: Maybe<InputField>;
  genericValidations?: Maybe<GenericFormFieldValidations>;
  id: Scalars['ID']['output'];
  interest?: Maybe<InputField>;
  interestOptions?: Maybe<Array<InterestOption>>;
  lastname?: Maybe<InputField>;
  pardotEndpoint?: Maybe<Scalars['String']['output']>;
  phonenumber?: Maybe<InputField>;
  showBowWave: Scalars['Boolean']['output'];
  subscribe?: Maybe<InputField>;
  subtitle?: Maybe<Scalars['String']['output']>;
  success?: Maybe<ModalSuccess>;
  textArea?: Maybe<InputField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ModalDepartmentContactDetails = {
  __typename?: 'ModalDepartmentContactDetails';
  address?: Maybe<ModalDepartmentAddressField>;
  email?: Maybe<ModalContactDetailsField>;
  fax?: Maybe<ModalContactDetailsField>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  mapsLink?: Maybe<Scalars['String']['output']>;
  phonenumber?: Maybe<ModalContactDetailsField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ModalDepartmentNewsletter = {
  __typename?: 'ModalDepartmentNewsletter';
  content?: Maybe<ModalNewsletterContent>;
  formName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalDonation = {
  __typename?: 'ModalDonation';
  content?: Maybe<ModalDonationContent>;
  formName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalDonationContent = {
  __typename?: 'ModalDonationContent';
  _uid: Scalars['String']['output'];
  address?: Maybe<InputField>;
  amount?: Maybe<InputField>;
  amountOptions?: Maybe<Array<SelectOption>>;
  buttonText?: Maybe<Scalars['String']['output']>;
  company?: Maybe<InputField>;
  component?: Maybe<Scalars['String']['output']>;
  contactMe?: Maybe<InputField>;
  contribution?: Maybe<InputField>;
  contributionOptions?: Maybe<Array<SelectOption>>;
  disclaimer?: Maybe<DisclaimerContent>;
  email?: Maybe<InputField>;
  error?: Maybe<ModalError>;
  genericValidations?: Maybe<GenericFormFieldValidations>;
  id: Scalars['ID']['output'];
  invoice?: Maybe<InputField>;
  mailCustomer?: Maybe<MailContent>;
  mailDamen?: Maybe<MailContent>;
  name?: Maybe<InputField>;
  otherAmount?: Maybe<InputField>;
  receiveInvoice?: Maybe<InputField>;
  showBowWave: Scalars['Boolean']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  success?: Maybe<ModalSuccess>;
  title?: Maybe<Scalars['String']['output']>;
  willTransfer?: Maybe<InputField>;
};

export type ModalDownload = {
  __typename?: 'ModalDownload';
  content?: Maybe<ModalDownloadContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalDownloadContent = IPardotHandler & {
  __typename?: 'ModalDownloadContent';
  _uid: Scalars['String']['output'];
  buttonText?: Maybe<Scalars['String']['output']>;
  company?: Maybe<InputField>;
  component: Scalars['String']['output'];
  disclaimer?: Maybe<DisclaimerContent>;
  email?: Maybe<InputField>;
  error?: Maybe<ModalError>;
  firstname?: Maybe<InputField>;
  genericValidations?: Maybe<GenericFormFieldValidations>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  lastname?: Maybe<InputField>;
  pardotEndpoint?: Maybe<Scalars['String']['output']>;
  /** @deprecated use 'subscribe' instead */
  signupText?: Maybe<Scalars['String']['output']>;
  subscribe?: Maybe<InputField>;
  success?: Maybe<ModalSuccess>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ModalError = {
  __typename?: 'ModalError';
  _uid: Scalars['ID']['output'];
  buttonText: Scalars['String']['output'];
  component: Scalars['String']['output'];
  image: ImageType;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ModalFeedback = {
  __typename?: 'ModalFeedback';
  content?: Maybe<ModalFeedbackContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalFeedbackContent = {
  __typename?: 'ModalFeedbackContent';
  _uid: Scalars['String']['output'];
  buttonText?: Maybe<Scalars['String']['output']>;
  company?: Maybe<InputField>;
  component?: Maybe<Scalars['String']['output']>;
  disclaimer?: Maybe<DisclaimerContent>;
  email?: Maybe<InputField>;
  error?: Maybe<ModalError>;
  firstname?: Maybe<InputField>;
  genericValidations?: Maybe<GenericFormFieldValidations>;
  id: Scalars['ID']['output'];
  lastname?: Maybe<InputField>;
  mailCustomer?: Maybe<MailContent>;
  mailDamen?: Maybe<MailContent>;
  phonenumber?: Maybe<InputField>;
  subscribe?: Maybe<InputField>;
  subtitle?: Maybe<Scalars['String']['output']>;
  success?: Maybe<ModalSuccess>;
  textArea?: Maybe<InputField>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ModalInteractiveMap = {
  __typename?: 'ModalInteractiveMap';
  content?: Maybe<ModalInteractiveMapContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalInteractiveMapContent = {
  __typename?: 'ModalInteractiveMapContent';
  _uid?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Scalars['String']['output']>;
  currentLocation?: Maybe<Scalars['String']['output']>;
  filterDamenCompany?: Maybe<Scalars['String']['output']>;
  filterMobileTitle?: Maybe<Scalars['String']['output']>;
  filterNewbuildYard?: Maybe<Scalars['String']['output']>;
  filterServiceHubs?: Maybe<Scalars['String']['output']>;
  filterShiprepairConversion?: Maybe<Scalars['String']['output']>;
  filterTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  localTime?: Maybe<Scalars['String']['output']>;
  mapLocation?: Maybe<Scalars['String']['output']>;
  searchTitle?: Maybe<Scalars['String']['output']>;
};

export type ModalNewsletter = {
  __typename?: 'ModalNewsletter';
  content?: Maybe<ModalNewsletterContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalNewsletterContent = IPardotHandler & {
  __typename?: 'ModalNewsletterContent';
  _uid: Scalars['String']['output'];
  buttonText?: Maybe<Scalars['String']['output']>;
  company?: Maybe<InputField>;
  component?: Maybe<Scalars['String']['output']>;
  country?: Maybe<InputField>;
  disclaimer?: Maybe<DisclaimerContent>;
  email?: Maybe<InputField>;
  error?: Maybe<ModalError>;
  firstname?: Maybe<InputField>;
  genericValidations?: Maybe<GenericFormFieldValidations>;
  id: Scalars['ID']['output'];
  lastname?: Maybe<InputField>;
  pardotEndpoint?: Maybe<Scalars['String']['output']>;
  subscribe?: Maybe<InputField>;
  subtitle?: Maybe<Scalars['String']['output']>;
  success?: Maybe<ModalSuccess>;
  termsOfUse?: Maybe<NewsletterTermsOfUse>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ModalQuote = {
  __typename?: 'ModalQuote';
  content?: Maybe<ModalQuoteContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type ModalQuoteContent = IPardotHandler & {
  __typename?: 'ModalQuoteContent';
  _uid: Scalars['String']['output'];
  buttonText?: Maybe<Scalars['String']['output']>;
  charterProductsPardotEndpoint?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  country?: Maybe<InputField>;
  countryOfOperation?: Maybe<InputField>;
  disclaimer?: Maybe<DisclaimerContent>;
  email?: Maybe<InputField>;
  error?: Maybe<ModalError>;
  firstname?: Maybe<InputField>;
  genericValidations?: Maybe<GenericFormFieldValidations>;
  id: Scalars['ID']['output'];
  interest?: Maybe<InputField>;
  interestOptions?: Maybe<Array<QuoteInterestOption>>;
  lastname?: Maybe<InputField>;
  pardotEndpoint?: Maybe<Scalars['String']['output']>;
  phonenumber?: Maybe<InputField>;
  productsPardotEndpoint?: Maybe<Scalars['String']['output']>;
  subscribe?: Maybe<InputField>;
  success?: Maybe<ModalSuccess>;
  textArea?: Maybe<InputField>;
  title?: Maybe<Scalars['String']['output']>;
  usedProductsPardotEndpoint?: Maybe<Scalars['String']['output']>;
};

export type ModalSuccess = {
  __typename?: 'ModalSuccess';
  _uid: Scalars['ID']['output'];
  buttonText: Scalars['String']['output'];
  component: Scalars['String']['output'];
  image: ImageType;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  linkTextPrefix?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MultiColumn = {
  __typename?: 'MultiColumn';
  _uid: Scalars['String']['output'];
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  columns?: Maybe<Array<MultiColumnItem>>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type MultiColumnItem = {
  __typename?: 'MultiColumnItem';
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  campaignSmallForm: MutationResponse;
  contactForm: MutationResponse;
  createProspect: MutationResponse;
  departmentContactForm: MutationResponse;
  departmentLeadRequest: MutationResponse;
  departmentNewsletterSubscription: MutationResponse;
  donationForm: MutationResponse;
  endChat: MutationResponse;
  feedbackForm: MutationResponse;
  leadRequest: MutationResponse;
  newsletterSubscription: MutationResponse;
  sendChatMessage: MutationResponse;
  startChat: StartChatMutationResponse;
};


export type MutationCampaignSmallFormArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: CampaignSmallFormPayload;
  site?: InputMaybe<Sites>;
};


export type MutationContactFormArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: ContactFormPayload;
  site?: InputMaybe<Sites>;
};


export type MutationCreateProspectArgs = {
  formName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  passedPardotEndpoint?: InputMaybe<Scalars['String']['input']>;
  payload: Scalars['JSON']['input'];
  site?: InputMaybe<Sites>;
  type: FormRequestType;
};


export type MutationDepartmentContactFormArgs = {
  formName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: ContactFormPayload;
  sidebarId: Scalars['String']['input'];
  site?: InputMaybe<Sites>;
};


export type MutationDepartmentLeadRequestArgs = {
  formName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: Scalars['JSON']['input'];
  site?: InputMaybe<Sites>;
  type: FormRequestType;
};


export type MutationDepartmentNewsletterSubscriptionArgs = {
  formName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: Scalars['JSON']['input'];
  site?: InputMaybe<Sites>;
};


export type MutationDonationFormArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: DonationFormPayload;
  sidebarId: Scalars['String']['input'];
  site?: InputMaybe<Sites>;
};


export type MutationEndChatArgs = {
  input?: InputMaybe<EndChatInput>;
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type MutationFeedbackFormArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: FeedbackFormPayload;
  site?: InputMaybe<Sites>;
};


export type MutationLeadRequestArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: Scalars['JSON']['input'];
  site?: InputMaybe<Sites>;
  type: FormRequestType;
};


export type MutationNewsletterSubscriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  payload: Scalars['JSON']['input'];
  site?: InputMaybe<Sites>;
};


export type MutationSendChatMessageArgs = {
  input: SendChatMessageInput;
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type MutationStartChatArgs = {
  input?: InputMaybe<StartChatInput>;
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  code: Scalars['Int']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type NewsBody = {
  __typename?: 'NewsBody';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  content?: Maybe<Array<Maybe<NewsDetailBodyContent>>>;
  id: Scalars['ID']['output'];
};

export type NewsDetailBody = ArticleBody | Breadcrumb | Cta | NewsBody | NewsFeed | Newsletter | TextWithImage | TextWithVideo | VideoPanoramic;

export type NewsDetailBodyContent = BodyContent | Image | Video;

export type NewsDetailContent = {
  __typename?: 'NewsDetailContent';
  _uid: Scalars['String']['output'];
  body?: Maybe<Array<Maybe<NewsDetailBody>>>;
  component: Scalars['String']['output'];
  header?: Maybe<HeaderArticle>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
  tags?: Maybe<Tags>;
};

export type NewsDetailPage = {
  __typename?: 'NewsDetailPage';
  content?: Maybe<NewsDetailContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type NewsFeed = {
  __typename?: 'NewsFeed';
  _uid: Scalars['String']['output'];
  articles?: Maybe<Array<Maybe<NewsFeedItem>>>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  tagsFilter?: Maybe<Tags>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NewsFeedItem = {
  __typename?: 'NewsFeedItem';
  _uid?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  tags?: Maybe<Tags>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NewsOverview = {
  __typename?: 'NewsOverview';
  content?: Maybe<NewsOverviewContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type NewsOverviewBody = AlgoliaNewsOverview | Breadcrumb | Cta | Newsletter | StreamThumbnails | Unknown;

export type NewsOverviewContent = {
  __typename?: 'NewsOverviewContent';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<NewsOverviewBody>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<HeaderSecondaryBase>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
};

export type Newsletter = {
  __typename?: 'Newsletter';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  component: Scalars['String']['output'];
  form?: Maybe<NewsletterForm>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  privacy?: Maybe<NewsletterPrivacy>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NewsletterForm = {
  __typename?: 'NewsletterForm';
  message?: Maybe<NewsletterFormMessage>;
  placeholder?: Maybe<Scalars['String']['output']>;
};

export type NewsletterFormMessage = {
  __typename?: 'NewsletterFormMessage';
  body?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NewsletterPrivacy = {
  __typename?: 'NewsletterPrivacy';
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type NewsletterTermsOfUse = {
  __typename?: 'NewsletterTermsOfUse';
  link: StoryblokLink;
  linkText: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type Numbers = {
  __typename?: 'Numbers';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<NumbersBlock>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NumbersBlock = {
  __typename?: 'NumbersBlock';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type Options = {
  __typename?: 'Options';
  items?: Maybe<Array<Option>>;
  title: Scalars['String']['output'];
};

export type ParallaxTiles = {
  __typename?: 'ParallaxTiles';
  _uid: Scalars['String']['output'];
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  columns?: Maybe<Array<ParallaxTilesColumns>>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  spacing?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ParallaxTilesColumnTiles = {
  __typename?: 'ParallaxTilesColumnTiles';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ParallaxTilesColumns = {
  __typename?: 'ParallaxTilesColumns';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tiles?: Maybe<Array<ParallaxTilesColumnTiles>>;
};

export type PardotForm = {
  __typename?: 'PardotForm';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  defaultHeight?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  subTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PartnerItem = {
  __typename?: 'PartnerItem';
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
};

export type Partners = {
  __typename?: 'Partners';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  partnerItems: Array<PartnerItem>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Popover = {
  __typename?: 'Popover';
  color: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type PressBody = BrandFeed | Cta | CardFeedAlternative;

export type PressContent = {
  __typename?: 'PressContent';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<PressBody>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<HeaderSecondaryBase>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
};

export type PressOverview = {
  __typename?: 'PressOverview';
  content?: Maybe<PressContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type Preview = {
  __typename?: 'Preview';
  isPreview: Scalars['Boolean']['output'];
};

export type Product = {
  __typename?: 'Product';
  family?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Field no longer supported */
  industryCluster?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  range: Scalars['String']['output'];
  salesforceName: Scalars['String']['output'];
  /** @deprecated Field no longer supported */
  shipType?: Maybe<Scalars['String']['output']>;
};

export type ProductDetails = {
  __typename?: 'ProductDetails';
  _uid: Scalars['String']['output'];
  assets?: Maybe<Assets>;
  availableOptionsText?: Maybe<Scalars['String']['output']>;
  compareText?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  configurationLink?: Maybe<StoryblokLink>;
  configurationText?: Maybe<Scalars['String']['output']>;
  configureLink?: Maybe<StoryblokLink>;
  configureText?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Scalars['String']['output']>>;
  downloadLink?: Maybe<StoryblokLink>;
  downloadText?: Maybe<Scalars['String']['output']>;
  enableDirectProductSheetDownload?: Maybe<Scalars['Boolean']['output']>;
  gallery3DImageText?: Maybe<Scalars['String']['output']>;
  galleryItems?: Maybe<GalleryItems>;
  gallerySectionId?: Maybe<Scalars['String']['output']>;
  highlights?: Maybe<Array<Maybe<Specification>>>;
  id: Scalars['ID']['output'];
  keyFigures?: Maybe<KeyFigures>;
  keyFiguresText?: Maybe<Scalars['String']['output']>;
  labels?: Maybe<Array<Tag>>;
  link?: Maybe<StoryblokLink>;
  mainImage?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Options>;
  overviewSectionId?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productType?: Maybe<ProductTypes>;
  quoteText?: Maybe<Scalars['String']['output']>;
  readMoreLink?: Maybe<StoryblokLink>;
  readMoreText?: Maybe<Scalars['String']['output']>;
  specifications?: Maybe<Specifications>;
  specificationsSectionId?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  title?: Maybe<Scalars['String']['output']>;
  virtualTourLink?: Maybe<StoryblokLink>;
  virtualTourText?: Maybe<Scalars['String']['output']>;
};

export type ProductFuelType = {
  __typename?: 'ProductFuelType';
  id: Scalars['ID']['output'];
  link: StoryblokLink;
  type: Scalars['String']['output'];
};

export type ProductFuelTypes = {
  __typename?: 'ProductFuelTypes';
  fuelTypes?: Maybe<Array<ProductFuelType>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ProductIndexItem = {
  __typename?: 'ProductIndexItem';
  configurationUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Scalars['String']['output']>>;
  family?: Maybe<Scalars['String']['output']>;
  familyId?: Maybe<Scalars['String']['output']>;
  familySynonyms?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  isInStock?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  nameSynonyms?: Maybe<Array<Scalars['String']['output']>>;
  objectID: Scalars['ID']['output'];
  previewImage: Scalars['String']['output'];
  range?: Maybe<Scalars['String']['output']>;
  rangeId?: Maybe<Scalars['String']['output']>;
  rangeSynonyms?: Maybe<Array<Scalars['String']['output']>>;
  salesforceName?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  tags?: Maybe<Array<ProductIndexItemTag>>;
  url?: Maybe<Scalars['String']['output']>;
  virtualTourUrl?: Maybe<Scalars['String']['output']>;
};

export type ProductIndexItemTag = {
  __typename?: 'ProductIndexItemTag';
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ProductIndexItemTagType>;
};

export enum ProductIndexItemTagType {
  Default = 'Default'
}

export type ProductList = {
  __typename?: 'ProductList';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<ProductQuickViewProduct>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProductQuickView = {
  __typename?: 'ProductQuickView';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<ProductQuickViewProduct>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProductQuickViewProduct = {
  __typename?: 'ProductQuickViewProduct';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  imageLabel?: Maybe<Scalars['String']['output']>;
  imageLabelColor?: Maybe<TagColor>;
  link?: Maybe<StoryblokLink>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['String']['output']>;
  specifications?: Maybe<Array<ProductSpecification>>;
};

export type ProductSlide = BaseSlide & {
  __typename?: 'ProductSlide';
  _uid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  sustainableMark?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Tag>>;
  textColor?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ProductSlider = {
  __typename?: 'ProductSlider';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<Maybe<ProductSliderContent>>>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ProductSliderContent = {
  __typename?: 'ProductSliderContent';
  description?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  slides: Array<ProductSlide>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProductSpecification = {
  __typename?: 'ProductSpecification';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ProductSpecificationType = {
  __typename?: 'ProductSpecificationType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ProductSpecificationTypeType;
  unitSymbol?: Maybe<Scalars['String']['output']>;
};

export enum ProductSpecificationTypeType {
  Boolean = 'Boolean',
  Number = 'Number',
  String = 'String'
}

export type ProductSpecificationTypesCollection = {
  __typename?: 'ProductSpecificationTypesCollection';
  items?: Maybe<Array<ProductSpecificationType>>;
};

export enum ProductTypes {
  CharterProduct = 'charterProduct',
  Product = 'product',
  UsedProduct = 'usedProduct'
}

export type ProductUsp = {
  __typename?: 'ProductUsp';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ProductUsps = {
  __typename?: 'ProductUsps';
  items?: Maybe<Array<ProductUsp>>;
};

export type ProjectDetails = {
  __typename?: 'ProjectDetails';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  ctaText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  projectAssets?: Maybe<ProjectDetailsAssets>;
  projectDownloads?: Maybe<ProjectDetailsDownloads>;
  projectGalleryItems?: Maybe<ProjectDetailsGalleryItems>;
  projectSpecifications?: Maybe<ProjectSpecifications>;
  shipyardInfo?: Maybe<ShipyardInfo>;
  title?: Maybe<Scalars['String']['output']>;
  virtualTourLink?: Maybe<StoryblokLink>;
  virtualTourText?: Maybe<Scalars['String']['output']>;
};

export type ProjectDetailsAsset = {
  __typename?: 'ProjectDetailsAsset';
  id: Scalars['ID']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: ProjectDetailsGalleryItemType;
};

export type ProjectDetailsAssets = {
  __typename?: 'ProjectDetailsAssets';
  items: Array<ProjectDetailsAsset>;
  title: Scalars['String']['output'];
};

export type ProjectDetailsDownload = {
  __typename?: 'ProjectDetailsDownload';
  downloadText: Scalars['String']['output'];
  file: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProjectDetailsDownloads = {
  __typename?: 'ProjectDetailsDownloads';
  items: Array<ProjectDetailsDownload>;
  showLess?: Maybe<Scalars['String']['output']>;
  showMore?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export enum ProjectDetailsGalleryItemFilter {
  All = 'all',
  Image = 'image',
  Video = 'video',
  VirtualTour = 'virtualTour'
}

export type ProjectDetailsGalleryItemImage = {
  __typename?: 'ProjectDetailsGalleryItemImage';
  alt?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  category?: Maybe<ProjectDetailsGalleryItemFilter>;
  id: Scalars['ID']['output'];
  type?: Maybe<ProjectDetailsGalleryItemType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ProjectDetailsGalleryItemItems = ProjectDetailsGalleryItemImage | ProjectDetailsGalleryItemVideo;

export enum ProjectDetailsGalleryItemType {
  Image = 'image',
  Video = 'video',
  VirtualTour = 'virtualTour'
}

export type ProjectDetailsGalleryItemVideo = {
  __typename?: 'ProjectDetailsGalleryItemVideo';
  category?: Maybe<ProjectDetailsGalleryItemFilter>;
  id: Scalars['ID']['output'];
  type?: Maybe<ProjectDetailsGalleryItemType>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoType?: Maybe<Scalars['String']['output']>;
};

export type ProjectDetailsGalleryItems = {
  __typename?: 'ProjectDetailsGalleryItems';
  assets?: Maybe<Array<Maybe<ProjectDetailsGalleryItemItems>>>;
  backToOverviewTitle: Scalars['String']['output'];
  tabs?: Maybe<Array<Maybe<ProjectDetailsGalleryTabs>>>;
};

export type ProjectDetailsGalleryTabs = {
  __typename?: 'ProjectDetailsGalleryTabs';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ProjectDetailsGalleryItemFilter>;
};

export type ProjectDetailsProductSpecification = {
  __typename?: 'ProjectDetailsProductSpecification';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ProjectIndexItem = {
  __typename?: 'ProjectIndexItem';
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  market?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  objectID: Scalars['ID']['output'];
  services?: Maybe<Array<Scalars['String']['output']>>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ProjectSpecifications = {
  __typename?: 'ProjectSpecifications';
  items?: Maybe<Array<Maybe<ProjectDetailsProductSpecification>>>;
};

export type Query = {
  __typename?: 'Query';
  aboutPage?: Maybe<AboutPage>;
  articleDetail?: Maybe<ArticleDetailPage>;
  articleOverview?: Maybe<ArticleOverview>;
  articlePage?: Maybe<ArticlePage>;
  chatAvailability?: Maybe<ChatAvailabilityResponse>;
  chatEnabled?: Maybe<ChatEnabledResponse>;
  chatMessages?: Maybe<ChatMessagesResponse>;
  contentPage?: Maybe<ContentPage>;
  familyPage?: Maybe<FamilyPage>;
  faqPage?: Maybe<FaqPage>;
  footer?: Maybe<Footer>;
  /** @deprecated Field no longer supported */
  getAllIndexedStories: QueryResponse;
  getHtmlSitemap?: Maybe<Array<SitemapHtmlLink>>;
  getIndexItem?: Maybe<AllIndexItems>;
  getSitemap?: Maybe<Array<SitemapLink>>;
  health?: Maybe<Health>;
  /** @deprecated Field no longer supported */
  index: MutationResponse;
  /** @deprecated Field no longer supported */
  indexAll: MutationResponse;
  /** @deprecated Field no longer supported */
  indexIndex: MutationResponse;
  insightsPage?: Maybe<InsightsPage>;
  locations?: Maybe<Array<Location>>;
  modalContact?: Maybe<ModalContact>;
  modalDepartmentsContact?: Maybe<Array<ModalDepartmentContact>>;
  modalDepartmentsNewsletter?: Maybe<Array<ModalDepartmentNewsletter>>;
  modalDonation?: Maybe<Array<Maybe<ModalDonation>>>;
  modalDownload?: Maybe<ModalDownload>;
  modalFeedback?: Maybe<ModalFeedback>;
  modalInteractiveMap?: Maybe<ModalInteractiveMap>;
  modalNewsletter?: Maybe<ModalNewsletter>;
  modalQuote?: Maybe<ModalQuote>;
  newsDetailPage?: Maybe<NewsDetailPage>;
  newsOverviewPage?: Maybe<NewsOverview>;
  pressOverviewPage?: Maybe<PressOverview>;
  preview?: Maybe<Preview>;
  /** @deprecated Field no longer supported */
  productSpecificationTypes?: Maybe<ProductSpecificationTypesCollection>;
  /** @deprecated Field no longer supported */
  querySuggest: QuerySuggestResponse;
  redirect?: Maybe<Redirect>;
  salesforceChat?: Maybe<SalesforceChat>;
  /** @deprecated Field no longer supported */
  search: SearchResponse;
  searchV2: SearchResponseV2;
  site?: Maybe<Site>;
  socialShare?: Maybe<SocialShare>;
  staticPaths?: Maybe<Array<Scalars['String']['output']>>;
  videoOverviewPage?: Maybe<VideoOverview>;
};


export type QueryAboutPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryArticleDetailArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryArticleOverviewArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryArticlePageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryChatAvailabilityArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryChatEnabledArgs = {
  input: ChatEnabledInput;
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryChatMessagesArgs = {
  input: ChatMessagesInput;
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryContentPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryFamilyPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryFaqPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryFooterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryGetAllIndexedStoriesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryGetHtmlSitemapArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryGetIndexItemArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryGetSitemapArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryHealthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryIndexArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryIndexAllArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryIndexIndexArgs = {
  index: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryInsightsPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryLocationsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryModalContactArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryModalDepartmentsContactArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
  slug: Scalars['String']['input'];
};


export type QueryModalDepartmentsNewsletterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
  slug: Scalars['String']['input'];
};


export type QueryModalDonationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryModalDownloadArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryModalFeedbackArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryModalInteractiveMapArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryModalNewsletterArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryModalQuoteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryNewsDetailPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryNewsOverviewPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryPressOverviewPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryPreviewArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryProductSpecificationTypesArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryQuerySuggestArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<SearchOptions>;
  query: Scalars['String']['input'];
  site?: InputMaybe<Sites>;
};


export type QueryRedirectArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
  sourceSlug: Scalars['String']['input'];
};


export type QuerySalesforceChatArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QuerySearchArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<SearchOptions>;
  query: Scalars['String']['input'];
  site?: InputMaybe<Sites>;
};


export type QuerySearchV2Args = {
  index?: InputMaybe<IndexNameV2>;
  locale?: InputMaybe<Scalars['String']['input']>;
  options: SearchOptionsV2;
  site?: InputMaybe<Sites>;
};


export type QuerySiteArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QuerySocialShareArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};


export type QueryStaticPathsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVideoOverviewPageArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Sites>;
};

export type QueryResponse = {
  __typename?: 'QueryResponse';
  code: Scalars['Int']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type QuerySuggestResponse = {
  __typename?: 'QuerySuggestResponse';
  hits?: Maybe<Array<Scalars['JSON']['output']>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type QuickNav = {
  __typename?: 'QuickNav';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Link>>;
  mainItems?: Maybe<Array<QuickNavItem>>;
  subItems?: Maybe<Array<QuickNavItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type QuickNavItem = {
  __typename?: 'QuickNavItem';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  title: Scalars['String']['output'];
};

export type QuoteInterestOption = {
  __typename?: 'QuoteInterestOption';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type Redirect = {
  __typename?: 'Redirect';
  from: StoryblokLink;
  to: Scalars['String']['output'];
  type: RedirectType;
};

export enum RedirectType {
  Permanent = 'Permanent',
  Temporary = 'Temporary'
}

export type Referral = {
  __typename?: 'Referral';
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  title: Scalars['String']['output'];
};

export type ReferralVideo = {
  __typename?: 'ReferralVideo';
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  videoId: Scalars['String']['output'];
  videoType: Scalars['String']['output'];
};

export type Referrals = {
  __typename?: 'Referrals';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  referrals?: Maybe<Array<Referral>>;
  title?: Maybe<Scalars['String']['output']>;
  video?: Maybe<ReferralVideo>;
};

export type SalesforceChat = {
  __typename?: 'SalesforceChat';
  content: SalesforceChatContent;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type SalesforceChatContent = {
  __typename?: 'SalesforceChatContent';
  _uid?: Maybe<Scalars['String']['output']>;
  buttonText: Scalars['String']['output'];
  closeButtonText: Scalars['String']['output'];
  closeCancelButtonText: Scalars['String']['output'];
  closeText: Scalars['String']['output'];
  closeTitle: Scalars['String']['output'];
  component?: Maybe<Scalars['String']['output']>;
  enableChat?: Maybe<Scalars['Boolean']['output']>;
  errorMessageUndelivered: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  loadingText: Scalars['String']['output'];
  loadingTitle: Scalars['String']['output'];
  placeholderText: Scalars['String']['output'];
  title: Scalars['String']['output'];
  unavailableText: Scalars['String']['output'];
  unavailableTitle: Scalars['String']['output'];
};

export type SearchOptions = {
  analytics?: InputMaybe<Scalars['Boolean']['input']>;
  attributesToReceive?: InputMaybe<Scalars['JSON']['input']>;
  attributesToSnippet?: InputMaybe<Scalars['JSON']['input']>;
  enableABTest?: InputMaybe<Scalars['Boolean']['input']>;
  explain?: InputMaybe<Scalars['JSON']['input']>;
  facetFilters?: InputMaybe<Scalars['JSON']['input']>;
  facets?: InputMaybe<Scalars['JSON']['input']>;
  getRankingInfo?: InputMaybe<Scalars['Boolean']['input']>;
  hitsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  responseFields?: InputMaybe<Scalars['JSON']['input']>;
  snippetEllipsesText?: InputMaybe<Scalars['JSON']['input']>;
};

export type SearchOptionsV2 = {
  facets?: InputMaybe<Array<Scalars['String']['input']>>;
  filters?: InputMaybe<Scalars['String']['input']>;
  hitsPerPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};

export type SearchResponse = {
  __typename?: 'SearchResponse';
  facets?: Maybe<Scalars['JSON']['output']>;
  hits?: Maybe<Array<Scalars['JSON']['output']>>;
  page?: Maybe<Scalars['Int']['output']>;
};

export type SearchResponseV2 = {
  __typename?: 'SearchResponseV2';
  facets?: Maybe<Scalars['JSON']['output']>;
  hits?: Maybe<Scalars['JSON']['output']>;
  nbHits?: Maybe<Scalars['Int']['output']>;
};

export type SelectOption = {
  __typename?: 'SelectOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SendChatMessageInput = {
  message: Scalars['String']['input'];
  sequenceId?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
};

export type SeoData = {
  __typename?: 'SeoData';
  metaData?: Maybe<MetaData>;
  ogData?: Maybe<MetaData>;
  twitterData?: Maybe<MetaData>;
};

export type Service = {
  __typename?: 'Service';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  description: Scalars['String']['output'];
  icon: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ServiceBanner = {
  __typename?: 'ServiceBanner';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  phone: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Services = {
  __typename?: 'Services';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Service>>;
  sectionId?: Maybe<Scalars['String']['output']>;
  sectionTitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ShipyardInfo = {
  __typename?: 'ShipyardInfo';
  buttonText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<ImageType>;
  link?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Site = {
  __typename?: 'Site';
  emailTranslations?: Maybe<EmailTranslations>;
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  navigation?: Maybe<SiteNavigation>;
  surveyMonkey?: Maybe<SurveyMonkey>;
  surveyMonkeyPopups?: Maybe<Array<SurveyMonkeyPopup>>;
  surveyMonkeyPopupsForPage?: Maybe<Array<SurveyMonkeyPopup>>;
};


export type SiteSurveyMonkeyPopupsForPageArgs = {
  id: Scalars['String']['input'];
};

export type SiteNavigation = {
  __typename?: 'SiteNavigation';
  closeMenuTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<SiteNavigationItem>>>;
  needHelp?: Maybe<SiteNavigationNeedHelp>;
  overflowItems?: Maybe<Array<Maybe<SiteNavigationOverflowItem>>>;
};

export type SiteNavigationBanner = {
  __typename?: 'SiteNavigationBanner';
  component?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: ImageType;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type SiteNavigationItem = {
  __typename?: 'SiteNavigationItem';
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  subItems?: Maybe<Array<Maybe<SiteNavigationSubItemsGroup>>>;
  supportingItem?: Maybe<SiteNavigationSupportingItem>;
  title: Scalars['String']['output'];
};

export type SiteNavigationNeedHelp = {
  __typename?: 'SiteNavigationNeedHelp';
  id: Scalars['ID']['output'];
  link: StoryblokLink;
  linkText: Scalars['String']['output'];
  subtitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SiteNavigationOverflowItem = {
  __typename?: 'SiteNavigationOverflowItem';
  iconDisplay?: Maybe<Scalars['String']['output']>;
  iconType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  link: StoryblokLink;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type SiteNavigationProduct = {
  __typename?: 'SiteNavigationProduct';
  description?: Maybe<Scalars['String']['output']>;
  iconType?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  link: StoryblokLink;
  title: Scalars['String']['output'];
};

export type SiteNavigationQuickLink = {
  __typename?: 'SiteNavigationQuickLink';
  id: Scalars['ID']['output'];
  link: StoryblokLink;
  title: Scalars['String']['output'];
};

export type SiteNavigationQuickLinks = {
  __typename?: 'SiteNavigationQuickLinks';
  component?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<SiteNavigationQuickLinksGroup>;
};

export type SiteNavigationQuickLinksGroup = {
  __typename?: 'SiteNavigationQuickLinksGroup';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  links?: Maybe<Array<Maybe<SiteNavigationQuickLink>>>;
};

export type SiteNavigationSecondSubItem = {
  __typename?: 'SiteNavigationSecondSubItem';
  id: Scalars['ID']['output'];
  link: StoryblokLink;
  linkText?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Maybe<SiteNavigationProduct>>>;
  title: Scalars['String']['output'];
};

export type SiteNavigationSecondSubItemsGroup = {
  __typename?: 'SiteNavigationSecondSubItemsGroup';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  subItems?: Maybe<Array<Maybe<SiteNavigationSecondSubItem>>>;
};

export type SiteNavigationSubItem = {
  __typename?: 'SiteNavigationSubItem';
  id: Scalars['ID']['output'];
  link: StoryblokLink;
  linkText?: Maybe<Scalars['String']['output']>;
  subItems?: Maybe<Array<Maybe<SiteNavigationSecondSubItemsGroup>>>;
  title: Scalars['String']['output'];
};

export type SiteNavigationSubItemsGroup = {
  __typename?: 'SiteNavigationSubItemsGroup';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  subItems?: Maybe<Array<Maybe<SiteNavigationSubItem>>>;
};

export type SiteNavigationSupportingItem = SiteNavigationBanner | SiteNavigationQuickLinks;

export type SitemapHtmlLink = {
  __typename?: 'SitemapHtmlLink';
  id: Scalars['ID']['output'];
  items?: Maybe<Scalars['JSON']['output']>;
  link: LinkDetail;
};

export type SitemapLink = {
  __typename?: 'SitemapLink';
  changeFrequency: Scalars['String']['output'];
  fullSlug: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastPublishedAt: Scalars['DateTime']['output'];
  priority: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  type: SitemapLinkType;
  uuid: Scalars['String']['output'];
};

export enum SitemapLinkType {
  Product = 'Product',
  Story = 'Story'
}

/** Enumeration of all possible sites to retreive content for */
export enum Sites {
  Albwardydamen = 'albwardydamen',
  Careerdamen = 'careerdamen',
  Damen = 'damen',
  Denavydamen = 'denavydamen',
  Nironstaal = 'nironstaal',
  Nlnavydamen = 'nlnavydamen',
  Shipleasefinance = 'shipleasefinance'
}

export type SocialShare = {
  __typename?: 'SocialShare';
  content: SocialShareContent;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type SocialShareContent = {
  __typename?: 'SocialShareContent';
  _uid?: Maybe<Scalars['String']['output']>;
  afterCopyTitle?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Scalars['String']['output']>;
  copyTitle?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  socials?: Maybe<Array<Maybe<SocialShareItem>>>;
  title: Scalars['String']['output'];
};

export type SocialShareItem = {
  __typename?: 'SocialShareItem';
  descriptiveTitle: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Specification = {
  __typename?: 'Specification';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  specLookupId?: Maybe<Scalars['ID']['output']>;
  value: Scalars['String']['output'];
};

export type Specifications = {
  __typename?: 'Specifications';
  items?: Maybe<Array<Maybe<Specification>>>;
  showLessText: Scalars['String']['output'];
  showMoreText: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type StandardProduct3DAsset = {
  __typename?: 'StandardProduct3DAsset';
  asset: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: GalleryItemType;
};

export type StandardProductAssets = {
  __typename?: 'StandardProductAssets';
  items: Array<Maybe<StandardProductGalleryAsset>>;
  title: Scalars['String']['output'];
};

export type StandardProductCompare = {
  __typename?: 'StandardProductCompare';
  _uid: Scalars['String']['output'];
  compareItems?: Maybe<Array<StandardProductCompareItem>>;
  component: Scalars['String']['output'];
  downloadText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type StandardProductCompareItem = {
  __typename?: 'StandardProductCompareItem';
  downloadLink?: Maybe<StoryblokLink>;
  enableDirectProductSheetDownload?: Maybe<Scalars['Boolean']['output']>;
  highlights?: Maybe<Array<Maybe<Specification>>>;
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  product?: Maybe<Product>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StandardProductDetails = {
  __typename?: 'StandardProductDetails';
  _uid: Scalars['String']['output'];
  assets?: Maybe<StandardProductAssets>;
  availableOptionsText?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  configureLink?: Maybe<StoryblokLink>;
  configureLinkText?: Maybe<Scalars['String']['output']>;
  configureText?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Scalars['String']['output']>>;
  downloadLink?: Maybe<StoryblokLink>;
  downloadLinkText?: Maybe<Scalars['String']['output']>;
  downloadText?: Maybe<Scalars['String']['output']>;
  enableDirectProductSheetDownload?: Maybe<Scalars['Boolean']['output']>;
  fuelTypes?: Maybe<ProductFuelTypes>;
  gallery3DImageText?: Maybe<Scalars['String']['output']>;
  galleryFullscreenButtonText?: Maybe<Scalars['String']['output']>;
  galleryItems?: Maybe<GalleryItems>;
  id: Scalars['ID']['output'];
  keyFigures?: Maybe<KeyFigures>;
  keyFiguresText?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  options?: Maybe<StandardProductOptions>;
  overviewSectionId?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productType?: Maybe<ProductTypes>;
  quoteText?: Maybe<Scalars['String']['output']>;
  showLessText?: Maybe<Scalars['String']['output']>;
  showMoreText?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  title?: Maybe<Scalars['String']['output']>;
  usps?: Maybe<ProductUsps>;
  /** @deprecated Field no longer supported */
  virtualTourLink?: Maybe<StoryblokLink>;
};

export type StandardProductGalleryAsset = StandardProduct3DAsset | StandardProductImageAsset | StandardProductVideoAsset | StandardProductVirtualTourAsset;

export type StandardProductImageAsset = {
  __typename?: 'StandardProductImageAsset';
  id: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: GalleryItemType;
};

export type StandardProductOptions = {
  __typename?: 'StandardProductOptions';
  items?: Maybe<Array<Option>>;
};

export type StandardProductProposition = {
  __typename?: 'StandardProductProposition';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  title: Scalars['String']['output'];
};

export type StandardProductPropositionHeader = {
  __typename?: 'StandardProductPropositionHeader';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overviewSectionId?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  propositions?: Maybe<Array<StandardProductProposition>>;
};

export type StandardProductVideoAsset = {
  __typename?: 'StandardProductVideoAsset';
  id: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: GalleryItemType;
  videoId: Scalars['String']['output'];
  videoType: GalleryVideoType;
};

export type StandardProductVirtualTourAsset = {
  __typename?: 'StandardProductVirtualTourAsset';
  asset: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  thumbnail: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: GalleryItemType;
};

export type StartChatInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  sequenceId?: InputMaybe<Scalars['String']['input']>;
};

export type StartChatMutationResponse = {
  __typename?: 'StartChatMutationResponse';
  isAvailable: Scalars['Boolean']['output'];
  sessionId?: Maybe<Scalars['String']['output']>;
};

export type StepByStep = {
  __typename?: 'StepByStep';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<StepByStepItem>>;
  spacing?: Maybe<Scalars['String']['output']>;
};

export type StepByStepItem = {
  __typename?: 'StepByStepItem';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overline?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StickyNavigation = {
  __typename?: 'StickyNavigation';
  _uid: Scalars['String']['output'];
  buttonColor?: Maybe<Scalars['String']['output']>;
  buttonLink?: Maybe<StoryblokLink>;
  buttonText?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<StickyNavigationItem>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StickyNavigationItem = {
  __typename?: 'StickyNavigationItem';
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type StoryTell = {
  __typename?: 'StoryTell';
  _uid: Scalars['String']['output'];
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  deepDive?: Maybe<Array<DeepDiveItem>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  spacing?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StoryTellDeepDive = {
  __typename?: 'StoryTellDeepDive';
  _uid: Scalars['String']['output'];
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  deepDive?: Maybe<Array<DeepDiveItem>>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  spacing?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StoryblokLink = {
  __typename?: 'StoryblokLink';
  anchor?: Maybe<Scalars['String']['output']>;
  /** @deprecated cachedUrl is deprecated. Use url instead. */
  cachedUrl?: Maybe<Scalars['String']['output']>;
  fieldtype?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  linktype?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Stream = {
  __typename?: 'Stream';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  items?: Maybe<Array<StreamItem>>;
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StreamItem = {
  __typename?: 'StreamItem';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  imageLabel?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StreamThumbnails = {
  __typename?: 'StreamThumbnails';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  date?: Maybe<Scalars['Date']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  items?: Maybe<Array<StreamThumbnailsItem>>;
  itemsTitle?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  overlay?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type StreamThumbnailsItem = {
  __typename?: 'StreamThumbnailsItem';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  date?: Maybe<Scalars['Date']['output']>;
  formattedDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  img?: Maybe<ImageType>;
  link?: Maybe<StoryblokLink>;
  title?: Maybe<Scalars['String']['output']>;
};

export type SurveyMonkey = {
  __typename?: 'SurveyMonkey';
  buttonText?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
};

export type SurveyMonkeyPopup = {
  __typename?: 'SurveyMonkeyPopup';
  id: Scalars['ID']['output'];
  link: StoryblokLink;
  pages?: Maybe<Array<Maybe<SurveyMonkeyPopupPage>>>;
  secondsToPop?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type SurveyMonkeyPopupPage = {
  __typename?: 'SurveyMonkeyPopupPage';
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type Tab = TabsContacts | TabsLinks;

export type TabFamily = {
  __typename?: 'TabFamily';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type Table = {
  __typename?: 'Table';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionLarge?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  table?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TableComparison = {
  __typename?: 'TableComparison';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionLarge?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  table?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TableWithRows = {
  __typename?: 'TableWithRows';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionLarge?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  table?: Maybe<Scalars['JSON']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Tabs = {
  __typename?: 'Tabs';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tabs?: Maybe<Array<Maybe<Tab>>>;
};

export type TabsContacts = {
  __typename?: 'TabsContacts';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  contacts?: Maybe<Array<Maybe<ContactContact>>>;
  id: Scalars['ID']['output'];
  showLessText?: Maybe<Scalars['String']['output']>;
  showMoreText?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type TabsFamily = {
  __typename?: 'TabsFamily';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tabs?: Maybe<Array<Maybe<TabFamily>>>;
};

export type TabsLink = {
  __typename?: 'TabsLink';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TabsLinks = {
  __typename?: 'TabsLinks';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<TabsLink>>>;
  title: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  color?: Maybe<TagColor>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export enum TagColor {
  DarkBlue = 'darkBlue',
  Green = 'green',
  Grey = 'grey',
  Orange = 'orange'
}

export type Tags = ITags & {
  __typename?: 'Tags';
  company?: Maybe<Array<Scalars['String']['output']>>;
  market?: Maybe<Array<Scalars['String']['output']>>;
  pillar?: Maybe<Array<Scalars['String']['output']>>;
  product?: Maybe<Array<Scalars['String']['output']>>;
};

export type Testimonial = {
  __typename?: 'Testimonial';
  company?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  key: Scalars['String']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  quote: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type TestimonialCards = {
  __typename?: 'TestimonialCards';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<TestimonialCardsItem>>;
  title: Scalars['String']['output'];
};

export type TestimonialCardsItem = {
  __typename?: 'TestimonialCardsItem';
  _uid: Scalars['String']['output'];
  buttonTitle?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image: ImageType;
  name: Scalars['String']['output'];
  quote?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  video?: Maybe<AssetVideoType>;
};

export type Testimonials = {
  __typename?: 'Testimonials';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Testimonial>>;
  sectionId?: Maybe<Scalars['String']['output']>;
  sectionTitle?: Maybe<Scalars['String']['output']>;
};

export type TextWithImage = {
  __typename?: 'TextWithImage';
  _uid: Scalars['String']['output'];
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  bowWave?: Maybe<Scalars['Boolean']['output']>;
  button?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  media: ContentMedia;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TextWithVideo = {
  __typename?: 'TextWithVideo';
  _uid: Scalars['String']['output'];
  alignment?: Maybe<Scalars['String']['output']>;
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  media: ContentMedia;
  previewImage?: Maybe<ImageType>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Tiles = {
  __typename?: 'Tiles';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rows?: Maybe<Array<TilesRow>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TilesColumn = {
  __typename?: 'TilesColumn';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  link: StoryblokLink;
  title?: Maybe<Scalars['String']['output']>;
};

export type TilesRow = {
  __typename?: 'TilesRow';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tiles?: Maybe<Array<TilesColumn>>;
  type: Scalars['String']['output'];
};

export type Unknown = {
  __typename?: 'Unknown';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
};

export type UsedProductDetails = {
  __typename?: 'UsedProductDetails';
  _uid: Scalars['String']['output'];
  assets?: Maybe<Assets>;
  availableOptionsText?: Maybe<Scalars['String']['output']>;
  compareText?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  configurationLink?: Maybe<StoryblokLink>;
  configurationText?: Maybe<Scalars['String']['output']>;
  configureLink?: Maybe<StoryblokLink>;
  configureText?: Maybe<Scalars['String']['output']>;
  ctaText?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Scalars['String']['output']>>;
  downloadLink?: Maybe<StoryblokLink>;
  downloadText?: Maybe<Scalars['String']['output']>;
  enableDirectProductSheetDownload?: Maybe<Scalars['Boolean']['output']>;
  gallery3DImageText?: Maybe<Scalars['String']['output']>;
  galleryItems?: Maybe<GalleryItems>;
  gallerySectionId?: Maybe<Scalars['String']['output']>;
  highlights?: Maybe<Array<Maybe<Specification>>>;
  id: Scalars['ID']['output'];
  keyFigures?: Maybe<KeyFigures>;
  keyFiguresText?: Maybe<Scalars['String']['output']>;
  labels?: Maybe<Array<Tag>>;
  link?: Maybe<StoryblokLink>;
  mainImage?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Options>;
  overviewSectionId?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productType?: Maybe<ProductTypes>;
  quoteText?: Maybe<Scalars['String']['output']>;
  readMoreLink?: Maybe<StoryblokLink>;
  readMoreText?: Maybe<Scalars['String']['output']>;
  specifications?: Maybe<Specifications>;
  specificationsSectionId?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  title?: Maybe<Scalars['String']['output']>;
  virtualTourLink?: Maybe<StoryblokLink>;
  virtualTourText?: Maybe<Scalars['String']['output']>;
};

export type UsedProductIndexItem = {
  __typename?: 'UsedProductIndexItem';
  built?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image: Scalars['String']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  isDamenVessel?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  objectID: Scalars['ID']['output'];
  priceRange?: Maybe<Scalars['String']['output']>;
  range?: Maybe<Scalars['String']['output']>;
  rangeSynonyms?: Maybe<Array<Scalars['String']['output']>>;
  salesforceName?: Maybe<Scalars['String']['output']>;
  sustainableMark?: Maybe<Popover>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type Video = {
  __typename?: 'Video';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  videoId?: Maybe<Scalars['String']['output']>;
  videoType?: Maybe<Scalars['String']['output']>;
};

export type VideoCenter = {
  __typename?: 'VideoCenter';
  _uid: Scalars['String']['output'];
  background?: Maybe<Scalars['String']['output']>;
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  link?: Maybe<StoryblokLink>;
  linkText?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videos: Array<VideoCenterVideo>;
};

export type VideoCenterVideo = {
  __typename?: 'VideoCenterVideo';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoType?: Maybe<Scalars['String']['output']>;
};

export type VideoFeed = {
  __typename?: 'VideoFeed';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  videos?: Maybe<Array<Maybe<VideoFeedItem>>>;
};

export type VideoFeedItem = {
  __typename?: 'VideoFeedItem';
  _uid?: Maybe<Scalars['String']['output']>;
  component?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Tags>;
  title?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoType?: Maybe<Scalars['String']['output']>;
};

export type VideoOverview = {
  __typename?: 'VideoOverview';
  content?: Maybe<VideoOverviewContent>;
  id: Scalars['ID']['output'];
  slug: Scalars['String']['output'];
};

export type VideoOverviewBody = Cta | Newsletter | VideoFeed | VideoPanoramic;

export type VideoOverviewContent = {
  __typename?: 'VideoOverviewContent';
  _uid?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Array<Maybe<VideoOverviewBody>>>;
  component?: Maybe<Scalars['String']['output']>;
  header?: Maybe<HeaderSecondaryBase>;
  id: Scalars['ID']['output'];
  seo?: Maybe<SeoData>;
  tags?: Maybe<Tags>;
};

export type VideoPanoramic = {
  __typename?: 'VideoPanoramic';
  _uid: Scalars['String']['output'];
  component: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  image?: Maybe<ImageType>;
  label?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
  videoType?: Maybe<Scalars['String']['output']>;
};

export type VideoType = {
  __typename?: 'VideoType';
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};
