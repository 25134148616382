import React from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@damen/ui'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'

const Container = styled.div``

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.x05}px ${theme.spacing.x3}px;
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: ${theme.typography.lineHeightTextSmall};
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1}px;
`

const OptionList: React.FC<StandardProductDetailsProps['options']> = ({ items, ...others }) => {
  if (!items || items?.length === 0) {
    return null
  }

  return (
    <Container {...others}>
      <List>
        {items.map((item) => (
          <ListItem key={item.id}>
            <Icon.StrikedCircle fill={theme.colors.marineBlack} width={13} />
            {item.title}
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

export default OptionList
