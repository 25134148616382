import { useEffect, useState } from 'react'

interface CustomCookieScriptEvent extends Event {
  detail: {
    categories: string[]
  }
}

interface CookieScriptState {
  action: 'accept' | 'reject'
  categories: string[]
}

declare global {
  interface Window {
    CookieScript?: {
      instance: {
        currentState: () => CookieScriptState
      }
    }
  }
}

const categories = ['strict', 'performance', 'targeting', 'functionality']

const checkAllCategoriesIncluded = (cookiescriptCategories: string[]) =>
  categories.every((category) => cookiescriptCategories.includes(category)) ? 'accepted' : 'declined'

const useCookieConsent = () => {
  const [state, setState] = useState<'uninitialized' | 'accepted' | 'declined'>('uninitialized')

  useEffect(() => {
    const checkCurrentState = () => {
      const currentState = window.CookieScript?.instance.currentState()
      setState(checkAllCategoriesIncluded(currentState?.categories))

      console.debug('CookieScript state: [oncheckCurrentState]')
    }

    if (window.CookieScript == null) {
      window.addEventListener('CookieScriptLoaded', () => {
        checkCurrentState()
        console.debug('CookieScript state: [onLoad]')
      })
    } else {
      checkCurrentState()
    }
  }, [])

  useEffect(() => {
    const handleAcceptAll = () => {
      setState('accepted')

      console.debug('CookieScript state: [handleAcceptAll]')
    }

    const handleSetCookieScriptPreferences = (e: CustomCookieScriptEvent) => {
      setState(checkAllCategoriesIncluded(e.detail.categories))

      console.debug(
        'CookieScript state: [handleSetCookieScriptPreferences]',
        window.CookieScript?.instance.currentState(),
      )
    }

    window.addEventListener('CookieScriptAcceptAll', handleAcceptAll)
    window.addEventListener('CookieScriptAccept', handleSetCookieScriptPreferences as EventListener)

    return () => {
      window.removeEventListener('CookieScriptAcceptAll', handleAcceptAll)
      window.removeEventListener('CookieScriptAccept', handleSetCookieScriptPreferences as EventListener)
    }
  }, [setState])

  return {
    cookieConsentState: state,
  } as const
}

export default useCookieConsent
