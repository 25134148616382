import React, { useLayoutEffect, useState } from 'react'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'
import { styled } from 'styled-components'
import { Heading, theme } from '@damen/ui'
import DescriptionToggle from './components/descriptionToggle'
import UspList from './components/uspList'
import OptionList from './components/optionList'
import KeyFigures from './components/keyFigures'
import DownloadProductSheet from './components/downloadProductSheet'
import ConfigureProduct from './components/configureProduct'
import SpecificationsToggle from './components/specificationsToggle'
import { ModalProps } from '@/components/UI/Modal/types'
import FuelFlexible from './components/fuelFlexible'

const { mediaQueries, spacing } = theme

const ContentRight = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isExpanded', 'columnHeight'].includes(prop),
})<{ isExpanded: boolean; columnHeight: number }>`
  position: relative;
  width: 100%;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

  @media ${mediaQueries.mediaQueryDesktop} {
    width: 50%;
    max-height: ${({ columnHeight }) => columnHeight}px;
    overflow: hidden;
    padding-bottom: ${spacing.x6}px;
  }

  ${({ isExpanded }) =>
    isExpanded &&
    `
    @media ${mediaQueries.mediaQueryDesktop} {
      max-height: 99999px;
      transition: max-height 0.6s cubic-bezier(1,0,1,0);
    }
  `}
`

const Title = styled.span`
  ${Heading.HeadingTwoStyle}
  margin-top: 0;
  margin-bottom: ${spacing.x1}px;
  display: none;

  @media ${mediaQueries.mediaQueryDesktop} {
    display: block;
  }
`

const StyledUspList = styled(UspList)`
  margin-top: ${spacing.x3}px;
`

const StyledOptionList = styled(OptionList)`
  margin-top: ${spacing.x3}px;
`

const StyledKeyFigures = styled(KeyFigures)`
  margin-top: ${spacing.x3}px;
`

const StyledDownloadProductSheet = styled(DownloadProductSheet)`
  margin-top: ${spacing.x3}px;
`

const StyledConfigureProduct = styled(ConfigureProduct)`
  margin-top: ${spacing.x3}px;
`

const StyledFuelFlexible = styled(FuelFlexible)`
  margin-top: ${spacing.x3}px;
`

const StyledSpecificationsToggle = styled(SpecificationsToggle)`
  display: none;
  bottom: 0;
  left: 0;

  @media ${mediaQueries.mediaQueryDesktop} {
    display: flex;
    margin-top: ${spacing.x2}px;
  }
`

interface RightColumnProps {
  leftColumnRef: React.RefObject<HTMLDivElement>
  title: string
  description: string
  options: StandardProductDetailsProps['options']
  downloadData: {
    link: StandardProductDetailsProps['downloadLink']
    linkText: StandardProductDetailsProps['downloadLinkText']
    text: StandardProductDetailsProps['downloadText']
    enableDirectProductSheetDownload: StandardProductDetailsProps['enableDirectProductSheetDownload']
    product: StandardProductDetailsProps['product']
  }
  setOpenModal: (props: ModalProps) => void
  configuration?: {
    text?: StandardProductDetailsProps['configureText']
    link?: StandardProductDetailsProps['configureLink']
    linkText?: StandardProductDetailsProps['configureLinkText']
  }
  usps: StandardProductDetailsProps['usps']
  keyFigures: StandardProductDetailsProps['keyFigures']
  showLessText: StandardProductDetailsProps['showLessText']
  showMoreText: StandardProductDetailsProps['showMoreText']
  fuelTypes: StandardProductDetailsProps['fuelTypes']
}

// Component has been split up in order to prevent the gallery from rerendering when the state changes
const RightColumn: React.FC<RightColumnProps> = ({
  leftColumnRef,
  title,
  description,
  options,
  downloadData,
  configuration,
  usps,
  keyFigures,
  setOpenModal,
  showLessText,
  showMoreText,
  fuelTypes,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  // Initial height based on left column on desktop
  const [leftColumnHeight, setLeftColumnHeight] = useState<number>(706)

  const handleResize = (): void => {
    if (window.innerWidth < theme.breakpoints.desktop) {
      return null
    }

    const columnHeight = leftColumnRef?.current?.offsetHeight

    if (columnHeight) {
      setLeftColumnHeight(Math.max(columnHeight))
    }

    return null
  }

  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <ContentRight id="product-information" isExpanded={isExpanded} columnHeight={leftColumnHeight}>
      <Title>{title}</Title>
      <DescriptionToggle description={description} />
      <StyledOptionList items={options.items} />
      <StyledConfigureProduct text={configuration?.text} link={configuration?.link} linkText={configuration?.linkText} />
      <StyledFuelFlexible title={fuelTypes?.title} fuelTypes={fuelTypes.fuelTypes} />
      <StyledUspList items={usps.items} />
      <StyledKeyFigures {...keyFigures} />
      <StyledDownloadProductSheet {...downloadData} setOpenModal={setOpenModal} />
      <StyledSpecificationsToggle
        id="product-information"
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        showLessText={showLessText}
        showMoreText={showMoreText}
      />
    </ContentRight>
  )
}

export default RightColumn
