import React, { useCallback, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { Heading, Icon, theme } from '@damen/ui'
import { useRouter } from 'next/router'

import { Product } from '@/queries/schema.generated'
import { showFamilyNav } from '@/lib/apollo/useApollo'
import { StandardProductPropositionHeaderContentProps } from '../UI/StandardProductPropositionHeader'
import SocialShare from '../SocialShare'
import Link from '../Link'
import { QUOTE_QUERYSTRING } from '../Forms/constants'
import type { ModalProps } from '../UI/Modal/types'

const { colors, mediaQueries, spacing, typography } = theme

export const QUOTE_BUTTON_TEST_ID = 'detail-quote-button'

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing.x2}px;

  @media ${mediaQueries.mediaQueryDesktop} {
    flex-direction: row;
    gap: ${spacing.x3}px;
  }

  @media ${mediaQueries.mediaQueryDesktopLarge} {
    gap: ${spacing.x10}px;
  }
`

const StyledLink = styled(Link.VNext)`
  font-size: ${typography.fontSizeTextSmall}px;
  line-height: ${typography.lineHeightTag};
  border-radius: 24px;
`

const SocialShareWrapper = styled.div`
  button {
    font-size: ${typography.fontSizeTextSmall}px;

    > span {
      background: transparent !important;
    }
  }
`

const PropositionItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.x1}px;
  font-size: ${typography.fontSizeTextSmall}px;
`

const PropositionList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.x2}px;
  padding: 0;

  @media ${mediaQueries.mediaQueryDesktop} {
    gap: ${spacing.x3}px;
  }
`

const StyledPropositionLink = styled(Link.VNext)`
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${colors.blue};
  }
`

const HeaderTitle = styled.span`
  ${Heading.HeadingFive};
  display: none;
  font-weight: bold;
`

const RightColumn = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hasPropositions', 'showTitle'].includes(prop),
})<{ hasPropositions?: boolean; showTitle?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.x2}px;

  ${({ hasPropositions }) =>
    hasPropositions &&
    css`
      ${SocialShareWrapper} {
        display: none;
      }

      ${PropositionItem} {
        &:nth-of-type(2) {
          display: none;
        }
      }
    `}

  ${({ showTitle }) =>
    showTitle &&
    css`
      ${HeaderTitle} {
        display: block;
      }

      ${PropositionList},
      ${SocialShareWrapper} {
        display: none;
      }
    `}

  @media ${mediaQueries.mediaQueryDesktop} {
    flex-direction: row;
    gap: ${spacing.x3}px;

    ${SocialShareWrapper} {
      display: ${({ showTitle }) => (showTitle ? 'none' : 'block')};
    }

    ${PropositionItem} {
      &:nth-of-type(2) {
        display: flex;
      }
    }
  }
`

interface Props {
  link: StandardProductPropositionHeaderContentProps['propositionHeaderLink']
  linkText: StandardProductPropositionHeaderContentProps['propositionHeaderLinkText']
  propositions: {
    id: StandardProductPropositionHeaderContentProps['propositions'][0]['id']
    title: StandardProductPropositionHeaderContentProps['propositions'][0]['title']
    link: StandardProductPropositionHeaderContentProps['propositions'][0]['propositionLink']
  }[]
  product: StandardProductPropositionHeaderContentProps['product']
  overviewSectionId: StandardProductPropositionHeaderContentProps['overviewSectionId']
  setOpenModal: (props: ModalProps) => void
}

const StandardProductPropositionHeader: React.FC<Props> = ({
  link,
  linkText,
  propositions,
  product,
  overviewSectionId,
  setOpenModal,
}) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const [showTitle, setShowTitle] = React.useState(false)
  const router = useRouter()

  const handleQuoteModal = useCallback(
    (product: Product) => {
      if (!setOpenModal) return null

      setOpenModal({
        type: 'quote',
        data: {
          product,
        },
      })
    },
    [setOpenModal],
  )

  useEffect(() => {
    const component = componentRef.current
    const overviewElement = document.getElementById(overviewSectionId)

    let pendingFrame: number | undefined
    const onScroll = () => {
      if (pendingFrame) {
        return
      }

      pendingFrame = requestAnimationFrame(() => {
        if (component) {
          const { clientHeight } = component

          // 1280 = breakpoint from SiteNavigation
          if (window.innerWidth < 1280) {
            // 113 = makes sure the SiteNavigation becomes visible above the PropositionHeader on smaller screens
            showFamilyNav(component.getBoundingClientRect().top < 113 - clientHeight)
          } else {
            // 138 = makes sure the SiteNavigation becomes visible above the PropositionHeader
            showFamilyNav(component.getBoundingClientRect().top < 138 - clientHeight)
          }
          // 8 = padding-top of the container, 64 = height of the PropositionHeader
          setShowTitle(component.getBoundingClientRect().top - 8 >= overviewElement.getBoundingClientRect().top - 64)
        }
        pendingFrame = undefined
      })
    }

    document.addEventListener('scroll', onScroll, {
      passive: true,
    })
    return () => document.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    const handleHashChange = (url: string) => {
      if (url.includes(QUOTE_QUERYSTRING)) {
        handleQuoteModal(product)
      }
    }

    router.events.on('hashChangeComplete', handleHashChange)
    return () => {
      router.events.off('hashChangeComplete', handleHashChange)
    }
  }, [router.events, product, handleQuoteModal])

  return (
    <Container ref={componentRef}>
      <StyledLink type="button" color="orange" link={link} text={linkText} testId={QUOTE_BUTTON_TEST_ID} />

      <RightColumn hasPropositions={propositions?.length > 0} showTitle={showTitle}>
        <HeaderTitle>{product.name}</HeaderTitle>
        <PropositionList>
          {propositions?.map(({ link, title, id }) => (
            <PropositionItem key={id}>
              {<Icon.Check fill={colors.marineBlack} />}
              {link?.url ? (
                <StyledPropositionLink type="link" link={link}>
                  {title}
                </StyledPropositionLink>
              ) : (
                <>{title}</>
              )}
            </PropositionItem>
          ))}
        </PropositionList>
        <SocialShareWrapper>
          <SocialShare variant="light" />
        </SocialShareWrapper>
      </RightColumn>
    </Container>
  )
}

export default StandardProductPropositionHeader
