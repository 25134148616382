import React from 'react'
import { useRouter } from 'next/router'
import { styled } from 'styled-components'
import { SURVEYMONKEY_QUERYSTRING } from '../../Forms/constants'

const Container = styled.div`
  position: fixed;
  bottom: 50%;
  right: 0;
  z-index: 5;
`

const Button = styled.button`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  padding: ${({ theme }) => theme.spacing.x1}px ${({ theme }) => theme.spacing.x2}px;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  background-color: #27355b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  border: none;
  margin-right: -35px;
`

interface Props {
  text: string
}

const SurveyMonkeyButton: React.FC<React.PropsWithChildren<Props>> = ({ text }) => {
  const router = useRouter()

  const handleClick = () => {
    void router.push(SURVEYMONKEY_QUERYSTRING)
  }

  return (
    <>
      <Container>
        <Button type="button" onClick={handleClick}>
          {text}
        </Button>
      </Container>
    </>
  )
}

export default SurveyMonkeyButton
