import React, { useMemo } from 'react'
import { MediaGallery, theme } from '@damen/ui'

import { Container } from '@/components/UI'
import { Props } from './types'
import { AssetType } from '@damen/ui/lib/esm/types/assets'
import type { ImageProps, VideoProps } from '@damen/ui/lib/esm/components/MediaGallery/types'
import { GalleryFragment } from '@/queries/fragments/GalleryFragment.generated'

const Gallery: React.FC<Props> = ({ content }) => {
  const { title, assets, galleryItems } = content

  const mappedAssets = useMemo(() => {
    return assets?.items?.map((item) => {
      const result = {
        ...item,
        type: item?.type as AssetType,
        thumbnail: {
          src: item?.thumbnail,
          alt: item?.title,
          sizes: `${theme.legacyMediaQueries.lg} 200px, ${theme.legacyMediaQueries.md} 150px, 50vw`,
        },
        overviewImage: {
          src: item?.thumbnail,
          alt: item?.title,
          sizes: `${theme.legacyMediaQueries.lg} 600px, ${theme.legacyMediaQueries.md} 460px, 100vw`,
        },
      }
      return result
    })
  }, [assets])

  const mappedGalleryItems = {
    ...galleryItems,
    assets: galleryItems?.assets.map((item) => {
      if (item.__typename === 'GalleryItemImage') {
        return item as ImageProps
      }

      if (item.__typename === 'GalleryItemVideo') {
        return item as VideoProps
      }
    }),
  } as {
    tabs: GalleryFragment['galleryItems']['tabs']
    assets: (ImageProps | VideoProps)[]
    backToOverviewTitle: string
  }

  return (
    <Container>
      <MediaGallery.Preview
      title={title}
      items={mappedAssets}
      galleryItems={mappedGalleryItems}
      previewLayout="wide"
      />
    </Container>
  )
}

export default Gallery
