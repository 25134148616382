import React from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@damen/ui'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'

const Container = styled.div``

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: ${theme.typography.lineHeightTextSmall};

  li {
    display: flex;
    align-items: flex-start;
    gap: ${theme.spacing.x1}px;

    svg {
      margin-top: ${theme.spacing.x1}px;
    }

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing.x2}px;
    }
  }
`

// TODO: Add typings
const UspList: React.FC<StandardProductDetailsProps['usps']> = ({ items, ...others }) => {
  if (!items || items?.length === 0) {
    return null
  }
  
  return (
    <Container {...others}>
      <List>
        {items.map((item) => (
          <li key={item.id}>
            <Icon.Check fill={theme.colors.marineBlack} width={12} />
            {item.title}
          </li>
        ))}
      </List>
    </Container>
  )
}

export default UspList
