import React, { useState } from 'react'
import { theme } from '@damen/ui'
import { styled } from 'styled-components'
import { Icon, SocialShare as SocialShareComponent } from '@damen/ui'
import { useQuery } from '@apollo/client'
import {
  GetSocialShareContentDocument,
  GetSocialShareContentQuery,
} from '@/queries/queries/Global/getSocialShare.generated'
import { GetTranslationsDocument, GetTranslationsQuery } from '@/queries/queries/Global/getTranslations.generated'
import { useBasePath } from '@/hooks'

const { colors, fonts } = theme

// These match with data in Storyblok
type ShareLinkType = 'facebook' | 'linkedin' | 'mail' | 'X' | 'whatsapp'

const getShareLink = (type: ShareLinkType, url: string, emailSubject?: string) => {
  const encodedUrl = encodeURIComponent(url)

  switch (type) {
    case 'facebook':
      return `https://facebook.com/sharer/sharer.php?u=${encodedUrl}`

    case 'linkedin':
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`

    case 'mail': {
      const body = encodeURIComponent('')

      return `mailto:?subject=${emailSubject || ''}&body=${(body && `${body}\n\n${encodedUrl}`) || encodedUrl}`
    }

    case 'whatsapp':
      return `https://api.whatsapp.com/send?text=${encodedUrl}`

    case 'X': {
      let title = ''

      if (typeof document !== 'undefined') {
        title = document.querySelector('h1')?.innerText
      }

      return `https://twitter.com/intent/tweet?text=${title ? `${encodeURIComponent(title)} #Damen` : ''}&url=${encodedUrl}`
    }

    default:
      return null
  }
}

const List = styled.ul`
  list-style: none;
  padding: 8px;
  margin: 0;
  min-width: 160px;
`

const ListItem = styled.li`
  padding: 12px 8px;

  &:not(:last-of-type) {
    border-bottom: 1px solid #e4e8eb;
  }

  svg {
    margin-right: 10px;
    fill: #011736 !important;

    path {
      fill: #011736 !important;
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  a,
  button {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: ${fonts.body.style.fontFamily};
    color: ${colors.marineBlack};

    &:hover {
      color: ${colors.blue};

      svg {
        fill: ${colors.blue} !important;

        path {
          fill: ${colors.blue} !important;
        }
      }
    }
  }
`

interface Props {
  alignment?: 'left' | 'right'
  variant?: 'light' | 'dark'
}

const SocialShare: React.FC<Props> = ({ alignment = 'left', variant }) => {
  const data = useQuery<GetSocialShareContentQuery>(GetSocialShareContentDocument, {
    fetchPolicy: 'cache-only',
  })
  const translationsData = useQuery<GetTranslationsQuery>(GetTranslationsDocument, {
    fetchPolicy: 'cache-only',
  })
  const { url } = useBasePath()

  const formattedUrl = url.split('?')[0]
  const seoURL = url.endsWith('.com/home') ? url.replace('/home', '') : formattedUrl
  
  const content = data.data?.socialShare?.content
  const [copied, setCopied] = useState(false)

  if (!content) return null

  const XData = content.socials.find((social) => social.type === 'X')
  const linkedInData = content.socials.find((social) => social.type === 'linkedin')
  const facebookData = content.socials.find((social) => social.type === 'facebook')
  const whatsAppData = content.socials.find((social) => social.type === 'whatsapp')
  const mailData = content.socials.find((social) => social.type === 'mail')
  const emailSubject = translationsData.data?.site?.emailTranslations?.subject

  return (
    <SocialShareComponent title={content.title} alignment={alignment} variant={variant}>
      <List>
        {XData && (
          <ListItem title={XData.descriptiveTitle}>
            <a href={getShareLink(XData.type as ShareLinkType, seoURL)} rel="noopener noreferrer" target="_blank">
              <Icon.X width={16} height={14} /> {XData.title}
            </a>
          </ListItem>
        )}
        {linkedInData && (
          <ListItem title={linkedInData.descriptiveTitle}>
            <a href={getShareLink(linkedInData.type as ShareLinkType, seoURL)} rel="noopener noreferrer" target="_blank">
              <Icon.LinkedIn width={16} height={21} /> {linkedInData.title}
            </a>
          </ListItem>
        )}
        {facebookData && (
          <ListItem title={facebookData.descriptiveTitle}>
            <a href={getShareLink(facebookData.type as ShareLinkType, seoURL)} rel="noopener noreferrer" target="_blank">
              <Icon.Facebook width={18} height={21} /> {facebookData.title}
            </a>
          </ListItem>
        )}
        {whatsAppData && (
          <ListItem title={whatsAppData.descriptiveTitle}>
            <a href={getShareLink(whatsAppData.type as ShareLinkType, seoURL)} rel="noopener noreferrer" target="_blank">
              <Icon.Whatsapp width={16} height={21} /> {whatsAppData.title}
            </a>
          </ListItem>
        )}
        {mailData && (
          <ListItem title={mailData.descriptiveTitle}>
            <a
              href={getShareLink(mailData.type as ShareLinkType, seoURL, emailSubject)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <Icon.Envelope width={18} height={18} /> {mailData.title}
            </a>
          </ListItem>
        )}
        {content.afterCopyTitle && content.copyTitle && (
          <ListItem>
            <button
              type="button"
              onClick={() => {
                setCopied(true)
                void navigator.clipboard.writeText(seoURL)
                setTimeout(() => {
                  setCopied(false)
                }, 30000)
              }}
            >
              <Icon.Link width={19} height={15} /> {copied ? content.afterCopyTitle : content.copyTitle}
            </button>
          </ListItem>
        )}
      </List>
    </SocialShareComponent>
  )
}

export default SocialShare
