import React, { useRef } from 'react'
import styled from 'styled-components'
import { mediaQueries, spacing } from '@damen/ui/lib/cjs/styles'
import { HeadingOne, HeadingTwoStyle } from '@damen/ui/lib/cjs/components/Heading'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'
import ProductGallery from './components/productGallery'
import type { CustomAssetProps } from '@/patterns/StandardProductDetails'
import type { ImageProps, TabItemProps, VideoProps, CustomAssetProps as CustomGalleryAssetProps } from '@damen/ui/lib/cjs/components/MediaGallery/types'
import { ModalProps } from '../UI/Modal/types'
import RightColumn from './rightColumn'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.x2}px;

  @media ${mediaQueries.mediaQueryDesktop} {
    flex-direction: row;
    gap: ${spacing.x3}px;
  }

  @media ${mediaQueries.mediaQueryDesktopLarge} {
    gap: ${spacing.x5}px;
  }
`

const ContentLeft = styled.div`
  width: 100%;

  @media ${mediaQueries.mediaQueryDesktop} {
    width: 50%;
  }
`

const StickyContent = styled.div`
  width: 100%;

  @media ${mediaQueries.mediaQueryDesktop} {
    position: sticky;
    z-index: 1;
    // Same height as the StandardProductPropositionHeader
    top: ${spacing.x9}px;
  }
`

const MobileTitle = styled(HeadingOne)`
  ${HeadingTwoStyle}
  margin-top: 0;

  @media ${mediaQueries.mediaQueryDesktop} {
    display: none;
  }
`

interface StandardProductDetailsCustomProps {
  title: string
  assets: {
    title: string
    items: CustomAssetProps[]
  }
  downloadData: {
    link: StandardProductDetailsProps['downloadLink']
    linkText: StandardProductDetailsProps['downloadLinkText']
    text: StandardProductDetailsProps['downloadText']
    enableDirectProductSheetDownload: StandardProductDetailsProps['enableDirectProductSheetDownload']
    product: StandardProductDetailsProps['product']
  }
  setOpenModal: (props: ModalProps) => void
  configuration?: {
    text?: StandardProductDetailsProps['configureText']
    link?: StandardProductDetailsProps['configureLink']
    linkText?: StandardProductDetailsProps['configureLinkText']
  }
  galleryItems: {
    tabs: TabItemProps[]
    assets: (ImageProps | VideoProps | CustomGalleryAssetProps)[]
    backToOverviewTitle: string
  }
}

type Props = Pick<
  StandardProductDetailsProps,
  | 'description'
  | 'options'
  | 'keyFigures'
  | 'usps'
  | 'overviewSectionId'
  | 'showMoreText'
  | 'showLessText'
  | 'fuelTypes'
  | 'galleryFullscreenButtonText'
> &
  StandardProductDetailsCustomProps

const StandardProductDetails: React.FC<Props> = ({
  title,
  description,
  options,
  keyFigures,
  usps,
  galleryItems,
  assets,
  overviewSectionId,
  configuration,
  downloadData,
  setOpenModal,
  showLessText,
  showMoreText,
  fuelTypes,
  galleryFullscreenButtonText,
}) => {
  const leftColumnRef = useRef<HTMLDivElement>(null)

  return (
    <Container id={overviewSectionId}>
      <ContentLeft>
        <StickyContent ref={leftColumnRef}>
          {/* Mobile title is the H1 */}
          <MobileTitle>{title}</MobileTitle>
          <ProductGallery items={assets.items} galleryItems={galleryItems} galleryFullscreenButtonText={galleryFullscreenButtonText} />
        </StickyContent>
      </ContentLeft>
      <RightColumn
        leftColumnRef={leftColumnRef}
        title={title}
        description={description}
        options={options}
        downloadData={downloadData}
        configuration={configuration}
        usps={usps}
        fuelTypes={fuelTypes}
        keyFigures={keyFigures}
        setOpenModal={setOpenModal}
        showLessText={showLessText}
        showMoreText={showMoreText}
      />
    </Container>
  )
}

export default StandardProductDetails
