import React from 'react'
import styled from 'styled-components'
import { Icon, LinearBlur, theme } from '@damen/ui'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  background: ${theme.colors.white};

  // LinearBlur component does not support passing styles
  // We want to offset the gradient to match the white background
  > div {
    bottom: 20px;
  }
`

const StyledButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isExpanded'].includes(prop),
})<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x1}px;
  background: none;
  border: none;
  z-index: 2;

  svg {
    transform: ${({ isExpanded }) => (isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  }

  &:focus {
    outline: 1px solid ${theme.colors.blue};
  }
`

interface SpecificationsToggleProps {
  id: string
  showMoreText: StandardProductDetailsProps['showMoreText']
  showLessText: StandardProductDetailsProps['showLessText']
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
  isExpanded: boolean
}

const SpecificationsToggle: React.FC<SpecificationsToggleProps> = ({
  id,
  isExpanded,
  setIsExpanded,
  showMoreText,
  showLessText,
  ...others
}) => {
  const buttonText = isExpanded ? showLessText : showMoreText

  return (
    <Container {...others}>
      <StyledButton
        isExpanded={isExpanded}
        aria-expanded={isExpanded}
        aria-controls={id}
        onClick={() => setIsExpanded((prevValue) => !prevValue)}
      >
        <Icon.ArrowBottom width={32} height={32} /> {buttonText}
      </StyledButton>
      {!isExpanded && <LinearBlur zindex={1} />}
    </Container>
  )
}

export default SpecificationsToggle
