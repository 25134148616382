import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@damen/ui'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'
import Link from '@/components/Link'
import { onDownloadClick } from '@/hooks/modal/download'
import { ModalProps } from '@/components/UI/Modal/types'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${theme.spacing.x1}px;
  width: 100%;
  padding: ${theme.spacing.x1}px;
  background: ${theme.colors.blueIce};
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightMedium};
`

const StyledLink = styled(Link.VNext)`
  display: inline-flex;

  a {
    padding: 0;
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: ${theme.typography.fontSizeTextSmall}px;
    text-decoration: underline;
    text-underline-offset: ${theme.spacing.x05}px;

    svg {
      width: 10px !important;
    }

    &:hover {
      background: transparent;
    }
  }
`

const StyledButton = styled(Link.VNext)`
  display: inline-flex;
  background: none;
  padding: 0;
  color: ${theme.colors.blue};
  font-size: ${theme.typography.fontSizeTextSmall}px;

  svg {
    width: 15px !important;
  }

  &:hover,
  &:focus {
    background: transparent;
  }
`

interface DownloadProductSheetProps {
  link: StandardProductDetailsProps['downloadLink']
  linkText: StandardProductDetailsProps['downloadText']
  enableDirectProductSheetDownload: StandardProductDetailsProps['enableDirectProductSheetDownload']
  product: StandardProductDetailsProps['product']
  text: string
  setOpenModal: (props: ModalProps) => void
  onClickDownload?: () => void
}

const DownloadButton = ({
  link,
  linkText,
  enableDirectProductSheetDownload,
  product,
  onClickDownload,
}: Pick<DownloadProductSheetProps, 'link' | 'linkText' | 'enableDirectProductSheetDownload' | 'product' | 'onClickDownload'>): React.ReactElement => {
  if (!linkText || !link?.url) return null
  const testId = product.name.replaceAll(' ', '-')

  if (enableDirectProductSheetDownload) {
    return (
      <StyledLink
        type="contentlink"
        link={link}
        text={linkText}
        icon={() => <Icon.FileDownload width={16} height={16} fill={theme.colors.blue} />}
        iconSize={16}
      />
    )
  }

  return (
    <StyledButton
      type="button"
      icon={Icon.FileDownload}
      iconSize={16}
      onClick={onClickDownload}
      dataAttrs={{
        'data-gtm-product': product.shipType,
        'data-gtm-action': 'download',
      }}
      link={null}
      text={linkText}
      testId={`productDetail.button-download-${testId}`}
    />
  )
}
const DownloadProductSheet: React.FC<DownloadProductSheetProps> = ({
  text,
  link,
  linkText,
  enableDirectProductSheetDownload,
  product,
  setOpenModal,
  ...others
}) => {
  if ((!link || link?.url === '') && !setOpenModal) {
    return null
  }

  const onClickDownload = useCallback(() => {
    onDownloadClick()
    setOpenModal({
      type: 'download',
      data: {
        link: link,
        title: product.name,
        product: {
          id: product.id,
          name: product.name,
          family: product.family,
          range: product.range,
          salesforceName: product.salesforceName,
        },
      },
    })
  }, [product.name, product.id, product.family, product.range, product.salesforceName, link, setOpenModal])

  return (
    <Container {...others}>
      {text}
      <DownloadButton
        link={link}
        linkText={linkText}
        enableDirectProductSheetDownload={enableDirectProductSheetDownload}
        product={product}
        onClickDownload={onClickDownload}
      />
    </Container>
  )
}

export default DownloadProductSheet
