import React from 'react'
import styled from 'styled-components'
import { theme } from '@damen/ui'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'
import Link from '@/components/Link'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${theme.spacing.x1}px;
  width: 100%;
  padding: ${theme.spacing.x1}px;
  background: ${theme.colors.blueIce};
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightRegular};
`

const StyledLink = styled(Link.VNext)`
  display: inline-flex;

  a {
    padding: 0;
    font-weight: ${theme.typography.fontWeightRegular};
    font-size: ${theme.typography.fontSizeTextSmall}px;
    text-decoration: underline;
    text-underline-offset: ${theme.spacing.x05}px;

    svg {
      width: 10px !important;
    }

    &:hover {
      background: transparent;
    }
  }
`

interface ConfigureProductProps {
  text: StandardProductDetailsProps['configureText']
  link: StandardProductDetailsProps['configureLink']
  linkText: StandardProductDetailsProps['configureLinkText']
}

const ConfigureProduct: React.FC<ConfigureProductProps> = ({ text, link, linkText, ...others }) => {
  if (!link || link?.url === '' || !linkText) {
    return null
  }

  return (
    <Container {...others}>
      {text} <StyledLink type="contentlink" link={link} text={linkText} />
    </Container>
  )
}

export default ConfigureProduct
