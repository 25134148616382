import React from 'react'
import StandardProductDetailsPattern from '@/patterns/StandardProductDetails'
import { ModalProps } from '../Modal/types'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'

// TODO This should be moved to a bloks folder where all patterns are present.
// The components folder should only contain components (like buttons) that are used in patterns.

interface Props {
  content: StandardProductDetailsProps
  setOpenModal: (props: ModalProps) => void
}

const StandardProductDetails: React.FC<React.PropsWithChildren<Props>> = ({ content, setOpenModal }) => {
  return <StandardProductDetailsPattern content={content} setOpenModal={setOpenModal} />
}

export default StandardProductDetails
