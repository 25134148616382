// TODO: These get 'resolved' when merging
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React from 'react'
import { theme } from '@damen/ui'
import Head from 'next/head'
import { useBasePath } from '@/hooks'
import { SeoData } from '@/types'
import { SCROLLBAR_BLUE, SCROLLBAR_BLUE_HOVER, SCROLLBAR_PX_WIDTH, SCROLLBAR_WHITE } from '@/styles/scrollbars'
import { getNextPublicSite, getNonce } from '@/utils/env'
import { SocialSciptsHead } from '@/lib/SocialScripts'

export interface Props {
  seoData?: SeoData
  structuredData?: any
  title?: string
}
const getSiteName = (site: string) => {
  switch (site) {
    case 'albwardydamen':
      return 'Albwardy Damen'
    case 'careerdamen':
      return 'Career'
    case 'nironstaal':
      return 'Niron Staal'
    case 'nlnavydamen':
      return 'Damen Naval'
    case 'denavydamen':
      return 'Damen Naval'
    case 'shipleasefinance':
      return 'Ship Lease Finance'

    case 'damen':
    default:
      return 'Damen'
  }
}

const BaseLayout: React.FC<React.PropsWithChildren<Props>> = ({ children, seoData, structuredData, title }) => {
  const site = getNextPublicSite()
  const { url } = useBasePath()
  const { metaData, ogData, twitterData } = seoData ?? {}
  const nonce = getNonce()
  const siteName = getSiteName(site)
  const pageTitle = metaData?.title ? `${metaData.title} - ${title ?? siteName}` : (title ?? siteName)

  // For all canonicals we remove the querystring
  const formattedUrl = url.split('?')[0]
  const seoURL = url.endsWith('.com/home') ? url.replace('/home', '') : formattedUrl

  // Determine final og tags/keys
  const { robots, description, image } = metaData ?? {}

  const ogTitle = ogData?.title ?? pageTitle
  const ogDescription = ogData?.description ?? description
  const ogImage = ogData?.image ?? image

  const twitterTitle = twitterData?.title ?? ogTitle
  const twitterDescription = twitterData?.description ?? ogDescription
  const twitterImage = twitterData?.image ?? ogImage

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" />
        <title>{pageTitle}</title>
        {robots && <meta name="robots" content={robots} />}
        {description && <meta name="description" content={description} />}
        {/* Start of OG data */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        {ogTitle && <meta property="og:title" content={ogTitle} />}
        {ogDescription && <meta property="og:description" content={ogDescription} />}
        {ogImage && <meta property="og:image" content={ogImage} />}
        {/* Start of twitter data */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@damen" />
        <meta property="twitter:url" content={url} />
        {twitterTitle && <meta property="twitter:title" content={twitterTitle} />}
        {twitterDescription && <meta property="twitter:description" content={twitterDescription} />}
        {twitterImage && <meta property="twitter:image" content={twitterImage} />}
        {/* Start of Apple Statusbar */}
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        {title && <meta name="apple-mobile-web-app-title" content={title} />}
        {/* Start of urls */}
        <link rel="alternate" hrefLang="en" href={seoURL} />
        <link rel="alternate" hrefLang="x-default" href={seoURL} />
        <link rel="canonical" href={seoURL} />
        {/* Start of favicons */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2546b1" />
        <meta name="msapplication-TileColor" content="#2d89ef" />
        <meta name="theme-color" content="#ffffff" />
        {/* Start of Structured */}
        {structuredData && (
          // eslint-disable-next-line react/no-danger
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
        )}
      </Head>
      <SocialSciptsHead nonce={nonce} />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        *,
        *::before,
        *::after {
          box-sizing: border-box;
          -webkit-overflow-scrolling: touch;
        }

        body {
          padding: 0;
          margin: 0;
          font-family: ${theme.fonts.body.style.fontFamily};
          color: ${theme.colors.marineBlack};
        }

        // If scrollbar styling should be adjusted here, also take a look at: src/styles/scrollbars.ts
        html,
        body {
          scrollbar-color: ${SCROLLBAR_BLUE} ${SCROLLBAR_WHITE};
        }

        body::-webkit-scrollbar {
          width: ${SCROLLBAR_PX_WIDTH};
        }

        body::-webkit-scrollbar-track {
          background: ${SCROLLBAR_WHITE};
        }

        body::-webkit-scrollbar-thumb {
          background-color: ${SCROLLBAR_BLUE};
        }

        body::-webkit-scrollbar-thumb:hover {
          background-color: ${SCROLLBAR_BLUE_HOVER};
        }

        // class is added to the html tag
        .ReactModal__Body--open {
          overflow: hidden;
          overscroll-behavior: none;
          
          body {
            overflow: hidden;
            overscroll-behavior: none;
          }

          #cookiescript_badge {
            z-index: 0;
          }
        }
      `}</style>
      {children}
    </>
  )
}

export default BaseLayout
