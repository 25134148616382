import React, { useState } from 'react'
import { Video } from '@damen/ui'

import { Container, Modal } from '@/components/UI'

import { ImageType, VideoType } from '@/types'
import setBodyScrollLock from '@/utils/bodyScrollLock'

interface Content {
  image: ImageType
  label?: string
  text?: string
  title?: string
  videoId: string
  videoType: VideoType
}

interface Props {
  content: Content
}

const VideoPanoramic: React.FC<React.PropsWithChildren<Props>> = ({ content, ...props }) => {
  const [showModal, setShowModal] = useState(false)

  const { image, label, title, text, videoId, videoType = 'vimeo' } = content

  return (
    <Container {...props}>
      <Video.Panoramic
        label={label}
        text={text}
        title={title}
        image={{
          alt: image.alt,
          src: image.filename,
          width: 1208,
          height: 420,
          aspectRatio: '1208:420',
        }}
        onClick={() => setShowModal(true)}
      />
      <Modal.Default
        isOpen={showModal}
        onClose={() => {
          setShowModal(false)
          setBodyScrollLock(false)
        }}
      >
        <Video.Default autoplay={1} mute={false} videoId={videoId} videoType={videoType} />
      </Modal.Default>
    </Container>
  )
}

export default VideoPanoramic
