import React from 'react'
import styled from 'styled-components'
import { theme } from '@damen/ui'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'

const Container = styled.div``

const Description = styled.div`
  margin-top: ${theme.spacing.x1}px;
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: ${theme.typography.lineHeightTextSmall};
  color: ${theme.colors.marineBlack};
`

interface DescriptionToggleProps {
  description: StandardProductDetailsProps['description']
}

// No toggle for now, might change in the future.
const DescriptionToggle: React.FC<DescriptionToggleProps> = ({ description, ...others }) => {
  return (
    <Container {...others}>
      <Description>{description}</Description>
    </Container>
  )
}

export default DescriptionToggle
