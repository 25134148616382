import React from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'
import { styled } from 'styled-components'

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  cursor: grab;
`

export function ModelGlb({ url }: { url: string }) {
  const { scene } = useGLTF(url)
  {/* eslint-disable-next-line react/no-unknown-property */}
  return <primitive object={scene} position={[0, 1, 0]} />
}

interface Props {
  url: string
}

const ModelRenderer: React.FC<Props> = ({ url }) => {
  return (
    <StyledWrapper>
      <Canvas
        shadows
        camera={{ zoom: 0.5, position: [5, 5, 5] }}
        style={{ width: '100%', height: '100%' }}
      >
        <ambientLight />

        {/* eslint-disable-next-line react/no-unknown-property */}
        <directionalLight intensity={0.5} />
        <ModelGlb url={url} />
        <OrbitControls zoomToCursor />
      </Canvas>
    </StyledWrapper>
  )
}

export default ModelRenderer
