import React, { FunctionComponent, useMemo } from 'react'
import { styled } from 'styled-components'

const StyledSection = styled.section`
  position: sticky;
  top: 0;
  z-index: 2;
  margin-top: 60px;

  // Breakpoint from SiteNavigation
  @media (min-width: 1280px) {
    margin-top: 86px;
  }
`

interface Props {
  bloks: Record<string, any>[]
  componentLookup: Record<string, FunctionComponent<any>>
  additionalProps?: Record<string, unknown>
}

export const BlokZone = ({ bloks, componentLookup, additionalProps = undefined }: Props) => {
  const renderedBloks = useMemo(
    () =>
      bloks.map((blok) => {
        const { __typename, id } = blok

        if (typeof componentLookup[__typename] !== 'undefined') {
          const props: any = {
            key: id,
            blok,
          }
          const CurrentComponent = componentLookup[__typename]

          if (__typename === 'StandardProductPropositionHeader') {
            return (
              <StyledSection key={id}>
                <CurrentComponent {...props} key={id} content={blok} {...additionalProps} />
              </StyledSection>
            )
          }

          return (
            <section key={id}>
              <CurrentComponent {...props} key={id} content={blok} {...additionalProps} />
            </section>
          )
        }

        return null
      }),
    [bloks, componentLookup, additionalProps],
  )

  return <>{renderedBloks}</>
}
