import React from 'react'
import styled from 'styled-components'
import { theme } from '@damen/ui'
import { ProductFuelTypes } from '@/queries/schema.generated'
import Link from '@/components/Link'
import { useRouter } from 'next/router'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: ${theme.spacing.x2}px;

  @media ${theme.mediaQueries.mediaQueryDesktop} {
    flex-direction: row;
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  gap: ${theme.spacing.x1}px ${theme.spacing.x3}px;
  list-style: none;
  padding-left: 0;
  margin: 0;
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: ${theme.typography.lineHeightTextSmall};
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
`

const CurrentPageItem = styled.span`
  padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;
  background: ${theme.colors.blue};
  border-radius: 5px;
  color: ${theme.colors.white};
`

const StyledLink = styled(Link.VNext)`
  padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;
  background: ${theme.colors.blueIce};
  border-radius: 5px;

  &:hover,
  &:focus {
    background: ${theme.colors.blue};
    color: ${theme.colors.white};
  }
`

const Label = styled.span`
  display: flex;
  font-weight: ${theme.typography.fontWeightMedium};
  white-space: nowrap;
`

type Props = Pick<ProductFuelTypes, 'fuelTypes' | 'title'>

const FuelFlexible: React.FC<Props> = ({ fuelTypes, title, ...others }) => {
  if (!fuelTypes || fuelTypes?.length === 0) {
    return null
  }

  const router = useRouter()

  return (
    <Container {...others}>
      <Label>{title}</Label>
      <List>
        {fuelTypes.map((fuelType) => (
          <ListItem key={fuelType.id}>
            {router.asPath.startsWith(fuelType.link.url) ? (
              <CurrentPageItem>{fuelType.type}</CurrentPageItem>
            ) : (
              <StyledLink type="link" link={fuelType.link}>
                {fuelType.type}
              </StyledLink>
            )}
          </ListItem>
        ))}
      </List>
    </Container>
  )
}

export default FuelFlexible
