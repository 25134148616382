// TODO: These automatically get 'resolved' when merging
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useCallback, useEffect, useRef } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

import BaseLayout, { Props as BaseProps } from '@/components/layout/Base'
import Footer from '@/components/layout/Default/Footer'

import { toggleNav } from '@/lib/apollo/useApollo'
import { Breadcrumb } from '@/components/UI'
import { setModalsStateBasedOnUrl } from '@/hooks/modal/queryString'
import Loading from '@/components/Loading'
import SiteNavigation from '@/components/UI/SiteNavigation/SiteNavigation'
import { Site } from '@/queries/schema.generated'
import SurveyMonkey from '@/components/SurveyMonkey'
import { FlamaCondSemibold, NHaasGroteskDSPro } from '@/themes/nextFont'
import setBodyScrollLock from '@/utils/bodyScrollLock'

/* NOTE: This component is on the refactor list */
interface Props extends BaseProps {
  contactData?: any
  departmentsContactData?: any
  departmentsNewsletterData?: any
  donationData?: any
  feedbackData?: any
  footerData: any
  loading?: boolean
  newsletterData?: any
  headerVariant?: 'light' | 'dark'
  siteData: Site
  children?: any
}

const Chat = dynamic(() => import('@/components/Chat'), {
  ssr: false,
})

const SurveyMonkeyPopup = dynamic(() => import('@/components/SurveyMonkeyPopup'), {
  ssr: false,
})

const DefaultLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  contactData,
  departmentsContactData,
  departmentsNewsletterData,
  donationData,
  feedbackData,
  footerData,
  loading,
  newsletterData,
  seoData,
  structuredData,
  title,
  headerVariant,
  siteData,
}) => {
  const navigationIsOpen = useReactiveVar(toggleNav)

  const router = useRouter()
  const prevRoute = useRef<string>(null)

  const handleRouteChangeStart = useCallback(() => {
    const route = router.asPath.split('?')[0]
    toggleNav(false)

    prevRoute.current = route
  }, [router.asPath])

  const handleRouteChange = useCallback((url: string) => {
    setBodyScrollLock(false)

    // From within a modal you can navigate to another pageLoading, here the modals should be closed
    // As that does not trigger the hashChangeComplete event we want to close them after a route change
    setModalsStateBasedOnUrl(url)
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, handleRouteChange, handleRouteChangeStart])

  if (loading) {
    console.warn('Unexpected loading state')
    return <Loading.Default />
  }

  return (
    <BaseLayout structuredData={structuredData} seoData={seoData} title={title}>
      <header>
        <SiteNavigation
          isOpen={navigationIsOpen}
          variant={headerVariant}
          items={siteData?.navigation?.items}
          overflowItems={siteData?.navigation?.overflowItems}
          needHelp={siteData?.navigation?.needHelp}
          closeMenuTitle={siteData?.navigation?.closeMenuTitle}
          currentPage={router.asPath}
        />
      </header>
      {/* font variables needed for all other components than navigation and default body styles */}
      <main id="site-maincontent" className={`${NHaasGroteskDSPro.variable} ${FlamaCondSemibold.variable}`}>
        <Wrapper>
          <Content>{children}</Content>
          <Chat />
          {<SurveyMonkey surveyMonkey={siteData?.surveyMonkey} />}
          {siteData?.surveyMonkeyPopupsForPage?.map((popup, index) => (
            <SurveyMonkeyPopup key={index} surveyMonkeyPopup={popup} />
          ))}
        </Wrapper>
        {/* 
          On Mobile the breadcrumb component should always be positioned above the footer.
          This cannot happen when we put the mobile variant inside the Wrapper above ->
          This has a min height of 100vh, so it can happen that only 1 other component is added to the page 
          and then the contents of the wrapper will not be as high as 100vh therefore the Breadcrumb will display
          beneath the content and not directly above the header.
        */}
        {children?.props?.content?.__typename === 'FamilyContent'
          ? children?.props?.content?.overview
              ?.filter((item: any) => item.__typename === 'Breadcrumb')
              ?.map((item: any) => {
                return (
                  <section key={item.id}>
                    <Breadcrumb content={item} variant="mobile" />
                  </section>
                )
              })
          : children?.props?.content?.body
              ?.filter((item: any) => item.__typename === 'Breadcrumb')
              ?.map((item: any) => {
                return (
                  <section key={item.id}>
                    <Breadcrumb content={item} variant="mobile" />
                  </section>
                )
              })}
      </main>
      <Footer
        id="site-footer"
        content={footerData.content}
        contactData={contactData}
        departmentsContactData={departmentsContactData}
        departmentsNewsletterData={departmentsNewsletterData}
        donationData={donationData}
        newsletterData={newsletterData}
        feedbackData={feedbackData}
      />
    </BaseLayout>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default DefaultLayout
