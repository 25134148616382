import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { DataList, Icon, theme, Heading } from '@damen/ui'
import { StandardProductDetails as StandardProductDetailsProps } from '@/queries/schema.generated'

const { colors, fonts, mediaQueries, spacing, typography } = theme

const Container = styled.div`
  position: relative;
  margin-top: ${spacing.x2}px;

  @media ${mediaQueries.mediaQueryDesktop} {
    margin-top: 40px;
  }
`

const Column = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isLastUnevenColumn'].includes(prop),
})<{ isLastUnevenColumn?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: none;
  width: 100%;

  ${({ isLastUnevenColumn }) =>
    !isLastUnevenColumn &&
    css`
      @media ${mediaQueries.mediaQueryDesktop} {
        width: calc(50% - ${spacing.x4}px);
      }
    `}
`

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: ${spacing.x2}px;

  dl {
    flex-direction: column;
    width: 100%;
    margin-top: 0;

    dt {
      margin-bottom: ${spacing.x2}px;
    }

    dd {
      padding: 0;

      &:not(:last-of-type) {
        margin-bottom: ${spacing.x3}px !important;
      }
    }
  }

  @media ${mediaQueries.mediaQueryDesktop} {
    flex-direction: row;
    gap: ${spacing.x4}px;

    dl {
      flex-direction: row;
      margin-bottom: 0;
      
      dd {
        &:not(:last-of-type) {
          margin-bottom: ${spacing.x2}px !important;
        }
      }
    }

  }
`

const SplitColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-grow: 1;
  max-width: none;
  width: calc(50% - ${spacing.x4}px);
`

const SplitColumnWrapper = styled(Columns).withConfig({
  shouldForwardProp: (prop) => !['isLastUnevenColumn', 'isOpen'].includes(prop),
})<{ isLastUnevenColumn?: boolean; isOpen?: boolean }>`
  flex-direction: row;
  gap: ${spacing.x4}px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 999px;
      transition: max-height 0.4s ease-in-out;
    `}

  ${({ isLastUnevenColumn }) =>
    !isLastUnevenColumn &&
    css`
      flex-direction: row;

      @media ${mediaQueries.mediaQueryDesktop} {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 0;

        ${SplitColumn} {
          width: 100%;
        }
      }
    `}

  @media ${mediaQueries.mediaQueryDesktop} {
    max-height: none;
  }
`

const ColumnTitle = styled.p`
  display: none;

  @media ${mediaQueries.mediaQueryDesktop} {
    display: block;
    margin-top: 0;
    margin-bottom: ${spacing.x2}px;
    font-family: ${fonts.body.style.fontFamily};
    font-size: ${typography.fontSizeTextSmall}px;
    font-weight: ${typography.fontWeightMedium};
    line-height: ${typography.lineHeightTextSmall};
    color: ${colors.marineBlack};
    min-height: ${typography.lineHeightTextSmall};
  }
`

const MobileColumnButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.x2}px;
  padding: 0;
  font-family: ${fonts.body.style.fontFamily};
  font-size: ${typography.fontSizeText}px;
  font-weight: ${typography.fontWeightMedium};
  line-height: ${typography.lineHeightTextSmall};
  color: ${colors.marineBlack};
  background: none;
  border: 0;

  svg {
    transform: rotate(90deg);
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        transform: rotate(-90deg);
      }
      transition: transform 0.4s ease-in-out;
    `}

  @media ${mediaQueries.mediaQueryDesktop} {
    display: none;
  }
`

const StyledHeading = styled(Heading.HeadingThree)`
  margin-top: 0;
  margin-bottom: ${spacing.x2}px;

  @media ${mediaQueries.mediaQueryDesktop} {
    font-size: ${typography.fontSizeHeadingFive}px;
  }
`

interface Specification {
  id: string
  label: string
  value: string
}

interface SpecificationColumn {
  items: Specification[]
  title: string
}

interface KeyFigureItemProps {
  isLastUnevenColumn: boolean
  title: SpecificationColumn['title']
  splitColumn1: Specification[]
  splitColumn2: Specification[]
  isDefaultOpen?: boolean
  id: string
}

const KeyFigureItem: React.FC<KeyFigureItemProps> = ({
  id,
  isLastUnevenColumn,
  title,
  splitColumn1,
  splitColumn2,
  isDefaultOpen,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen)
  return (
    <>
      <ColumnTitle>{title}</ColumnTitle>
      <MobileColumnButton aria-expanded={isOpen} aria-controls={id} onClick={() => setIsOpen((prevValue) => !prevValue)} isOpen={isOpen}>
        {title}
        <Icon.LeftChevron width={12} height={12} fill={theme.colors.marineBlack} />
      </MobileColumnButton>
      <SplitColumnWrapper id={id} isOpen={isOpen} isLastUnevenColumn={isLastUnevenColumn}>
        <SplitColumn>
          <DataList.Default data={splitColumn1} valueAlignment="left" />
        </SplitColumn>
        <SplitColumn>
          <DataList.Default data={splitColumn2} valueAlignment="left" />
        </SplitColumn>
      </SplitColumnWrapper>
    </>
  )
}

const KeyFigures: React.FC<StandardProductDetailsProps['keyFigures']> = ({ title, columns, ...others }) => {
  const isUnevenColumnCount = columns.length % 2 !== 0

  return (
    <Container {...others}>
      <StyledHeading>{title}</StyledHeading>
      <Columns>
        {columns.map((column, index) => {
          // We always want to split in 2 columns for mobile view
          const splitColumn1 = column?.items.slice(0, Math.ceil(column.items.length / 2))
          const splitColumn2 = column?.items.slice(Math.ceil(column.items.length / 2))

          return (
            // index is 0 based
            <Column isLastUnevenColumn={isUnevenColumnCount && columns.length === index + 1} key={column.title}>
              <KeyFigureItem
                id={`${index}-${splitColumn1[0].id}-${splitColumn2[0].id}`}
                title={column.title}
                isLastUnevenColumn={isUnevenColumnCount && columns.length === index + 1}
                isDefaultOpen={index === 0}
                splitColumn1={splitColumn1}
                splitColumn2={splitColumn2}
              />
            </Column>
          )
        })}
      </Columns>
    </Container>
  )
}

export default KeyFigures
